import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./VersionControl.css";
import { Dialog } from "primereact/dialog";
import { useState } from 'react';
import { getErrorMessage, getSuccessMessage, getToastMessage, loadingData } from "../../Helper/Helper";
import { InputText } from "primereact/inputtext";
import { handleToastErrorCatch, statementError } from "../../Helper/ToastHelper";
import httpService from "../../../services/http.service";
import { toast } from "react-toastify";
import { connectSocket } from "../../Helper/SocketHelper";
import { useRef } from 'react';
const VersionControl = ({ props }) => {

  const [gitIntegratedDialog, setGitIntegratedDialog] = useState(false);
  const [gitLink, setGitLink] = useState(null);
  const [isGitActive, setIsGitActive] = useState(false);
  const [testcaseLoader, setTestcaseLoader] = useState(false);
  const [unlinkGit, setUnlinkGit] = useState(false);
  const [loadingJar, setLoadingJar] = useState(false);
  const [privateGit, setPrivateGit] = useState(false);
  const [gitaccesstoken, setGitaccesstoken] = useState("");
  const connection = useRef(null);

  const versionControlSocket = () => {
    connectSocket(
      "testpad",
      "S_" + props?.rowData?.["Suite ID"]?.value + "/private",
      connection,
      notifyClient
    );
  }

  const notifyClient = (e) => {
    if (e?.body) {
      let wsBody = JSON.parse(e.body);
      if (
        wsBody?.message?.toLowerCase() === "testcase(s) updated successfully"
      ) {
        reloadjar();
        setGitIntegratedDialog(false);
        props.addTestcase(null, props?.rowData?.["Suite ID"]?.value, null, props?.rowData);
        return;
      }
      if (
        wsBody?.message?.toLowerCase() === "testcase(s) created successfully"
      ) {
        reloadjar();
        setGitIntegratedDialog(false);
        props.addTestcase(null, props?.rowData?.["Suite ID"]?.value, null, props?.rowData);
        return;
      }
      if (wsBody?.message?.toLowerCase() === "jar created successfully!") {
        clearData();
        return;
      } else {
        statementError(wsBody?.message);
        clearData();
      }
    }
  };

  const integrateGit = (e, isGitIntegrated) => {
    setIsGitActive(isGitIntegrated);
    setGitIntegratedDialog(true);
  };

  const clearData = () => {
    setTestcaseLoader(false);
    setLoadingJar(false);
    setGitIntegratedDialog(false);
    setPrivateGit(false);
    setGitLink(null);
    setGitaccesstoken("");
    connection?.current?.unsubscribe();
  }

  const gitControl = (action) => {
    connection?.current?.unsubscribe();
    let payload = {};
    if (privateGit) {
      if (gitaccesstoken) {
        payload = {
          s_id: props?.rowData?.["Suite ID"]?.value,
          source: gitLink,
          accessToken: gitaccesstoken,
        };
      } else {
        statementError("Personal Access Token is Required for Private Repositories.");
        return;
      }
    } else {
      payload = {
        s_id: props?.rowData?.["Suite ID"]?.value,
        source: gitLink,
        accessToken: gitaccesstoken,
      };
    }
    switch (action) {
      case "integrate":
        setTestcaseLoader(true);
        versionControlSocket();

        httpService
          .integrateGit(payload)
          .then((data) => {
            setLoadingJar(true);
            if (data?.operation?.toLowerCase() === "success") {
              getToastMessage(data);
            } else {
              getErrorMessage(data);
              clearData();
            }
          })
          .catch((err) => {
            handleToastErrorCatch(err, clearData)
          });
        break;
      case "update":
        setTestcaseLoader(true);
        versionControlSocket();
        httpService
          .updateGit(payload)
          .then((data) => {
            if (data?.operation?.toLowerCase() === "success") {
              getToastMessage(data);
            } else {
              getErrorMessage(data);
              clearData();
            }
          })
          .catch((err) => {
            handleToastErrorCatch(err, clearData)
          });
        break;
      default:
        break;
    }
  };

  const removeGit = () => {
    setTestcaseLoader(true);
    setUnlinkGit(true);
    setLoadingJar(true);
    httpService
      .deleteGit(props?.rowData?.["Suite ID"]?.value)
      .then((data) => {
        data?.operation?.toLowerCase() === "success"
          && toast.success(
            `Git Unlinked from Suite Id: ${props?.rowData?.["Suite ID"]?.value} `,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          )
        getErrorMessage(data);
        props.setShowTestcase(false);
        setUnlinkGit(false);
        clearData();
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => {
          setUnlinkGit(false);
          setLoadingJar(false);
          setTestcaseLoader(false);
        });
      });
  };

  const reloadjar = (action) => {
    setTestcaseLoader(true);
    setLoadingJar(true);
    let s_id = props?.rowData?.["Suite ID"]?.value;
    httpService
      .createJar(s_id)
      .then((gitData) => {
        if (action === "async") {
          versionControlSocket();
          setLoadingJar(false);
          setTestcaseLoader(false);
          setGitIntegratedDialog(false);
          setPrivateGit(false);
          setGitaccesstoken("");
        }
        getToastMessage(gitData);
      })
      .catch((err) => {
        statementError("Something Went Wrong while Reloading the JAR. Please Try Again!");
        clearData();
      });
  };

  return (
    <>
      <FontAwesomeIcon
        onClick={(e) => {
          if (!(props?.freeUserStats && props?.freeUserStats?.gitIntegration === false)) {
            props?.val && setGitLink(props.val)
            integrateGit(e, props.isActive);
          }
        }}
        title={`${props?.isActive && props?.rowData?.Executable?.value
          ? "Git Integrated"
          : !props?.rowData?.Executable?.value && props?.isActive
            ? "Jar Creation in Progress"
            : (props?.freeUserStats && props?.freeUserStats?.gitIntegration === false)
              ? "Git integration is not enabled for free accounts"
              : "Click to Integrate Git"
          }`}
        className={`ms-1 tabIcon iconclick ${(props?.freeUserStats && props?.freeUserStats?.gitIntegration === false) ? "disabledGitIcon" : props?.isActive && props?.rowData?.Executable?.value
          ? "colorTheme"
          : !props?.rowData?.Executable?.value && props.isActive
            ? "blink"
            : ""
          }
        } `}
        icon={faGithub}
      />
      <Dialog
        blockScroll={true}
        draggable={false}
        header={`${isGitActive ? "Update/Reload" : "Integrate"} Git`}
        visible={gitIntegratedDialog}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "65vw" }}
        onHide={() => {
          clearData();
        }}
        footer={() => (
          <>
            <div>
              {isGitActive && (
                <>
                  <button
                    disabled={gitLink ? false : true}
                    onClick={() => {
                      removeGit();
                    }}
                    className="btn-success mt-3  btn themeBtn"
                  >
                    Remove Git
                  </button>
                  <button
                    disabled={gitLink ? false : true}
                    onClick={() => {
                      reloadjar("async")
                    }}
                    className="btn-success mt-3  btn themeBtn"
                  >
                    Reload JAR
                  </button>
                </>
              )}
              <button
                disabled={gitLink ? false : true}
                onClick={() => {
                  isGitActive ? gitControl("update") : gitControl("integrate");
                }}
                className="btn-success mt-3  btn themeBtn"
              >
                {isGitActive ? "Update/Reload Git" : "Integrate Git"}
              </button>
            </div>
          </>
        )}
      >
        <>
          {!testcaseLoader ? (
            <div className="w-100">
              <div className="overHeadAnalytics ms-2 text-center ">
                <strong>URL</strong>
              </div>
              <div className="text-center mt-1">
                <InputText
                  type="text"
                  className="inputHeight w-75"
                  value={gitLink ? gitLink : ""}
                  placeholder={"Git Link"}
                  onChange={(e) => {
                    setGitLink(e.target.value);
                  }}
                />
                <br />
                {gitLink && (
                  <div className="mt-2">
                    <input
                      id="privatepublic"
                      type="checkbox"
                      checked={privateGit}
                      onChange={(e) => {
                        setPrivateGit(e.target.checked);
                      }}
                    />
                    <label for="privatepublic">
                      <span className="ms-2">
                        {" "}
                        Check if Repository is Private.
                      </span>
                    </label>
                  </div>
                )}
                {privateGit && (
                  <>
                    <div className="overHeadAnalytics ms-2 mt-2 text-center ">
                      <strong>Access Token</strong>
                    </div>
                    <div className="text-center mt-1">
                      <InputText
                        type="text"
                        className="inputHeight w-75"
                        value={gitaccesstoken ? gitaccesstoken : ""}
                        placeholder={"Personal Access Token"}
                        onChange={(e) => {
                          setGitaccesstoken(e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : !unlinkGit ? (
            loadingData(
              loadingJar ? "Creating JAR..." : "Fetching TestCases..."
            )
          ) : (
            loadingData("Unlinking Git...")
          )}
        </>
      </Dialog>
    </>
  );
};

export default VersionControl;
