import Auth from "../../Pages/AuthPage/Auth";
import "./Login.css";
import passwordIcon from "../../../assets/passwordIcon.svg";
import userIcon from "../../../assets/userIcon.svg";
import domainIcon from "../../../assets/domainIcon.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login, loginViaSsoFn } from "../../../actions/auth";
import { connect } from "react-redux";
import { Password } from "primereact/password";
import md5Hex from "md5-hex";
import { isJwtExpired } from "jwt-check-expiration";
import { logPageTitleForGA } from "../../Helper/Helper";
import {
  getInfoToast,
  getSuccessToast,
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import authService from "../../../services/auth.service";
import { Button } from "primereact/button";

const Login = (props) => {
  const navigate = useNavigate();
  const [loginClicked, setLoginClicked] = useState(false);
  const username = useRef("");
  const password = useRef("");
  const domain = useRef("");
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const [loginViaSso, setLoginViaSso] = useState(false);
  const [SsoProceed, setSsoProceed] = useState(false);
  const [loginViaSsoBtnLoading, setLoginViaSsoBtnLoading] = useState(false);
  const [signInViaCredentials, setSignInViaCredentials] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let title = "Jewel | Login";
    document.title = title;
    logPageTitleForGA(title);
    if (props.isLoggedIn) {
      if (!isJwtExpired(localStorage.getItem("usertoken"))) {
        toast.info("You are already Logged in !", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,

          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/autolytics/insights", { replace: true });
      } else {
        getInfoToast("Session Expired ! Please Log In to continue. ");
      }
    }
    if (searchParams.get("session")) {
      props
        .dispatch(loginViaSsoFn(searchParams.get("session")))
        .then((data) => {
          getSuccessToast("Login Successful !");
          const redirectPath = sessionStorage.getItem("redirect");
          if (redirectPath) {
            if (
              redirectPath === "/" ||
              redirectPath === "/signup" ||
              redirectPath === "/login"
            ) {
              navigate("/autolytics/insights", { replace: true });
            } else {
              navigate(redirectPath, { replace: true });
            }
            sessionStorage.removeItem("redirect");
          } else {
            navigate("/autolytics/insights", { replace: true });
          }
        })
        .catch((err) => {
          if (err.message.toLowerCase() === "account verification required!") {
            getInfoToast("Account verification required!");
            navigate("/account-verification", {
              state: {
                firstName: err?.data?.firstName,
                lastName: err?.data?.lastName,
                username: err?.data?.username,
                email: err?.data?.email,
                hashedPassword: md5Hex(password.current),
                companyName: err?.data?.company,
              },
            });
          }
        })
        .finally(() => {
          setLoginClicked(false);
        });
    }
    return () => {
      if (!window.location.hash.includes("/signup"))
        sessionStorage.removeItem("redirect");
    };
  }, []);

  const loginUser = (e, ssoLogin) => {
    e.preventDefault();
    // if (loginViaSso) {
    //   if (SsoProceed) {
    //     if (domain.current && domain.current.includes('@')) {
    //       statementError("‘@’ is not allowed!");
    //       return;
    //     }
    //     setLoginViaSsoBtnLoading(true);
    //     authService.loginViaSso(domain.current)
    //       .then((data) => {
    //         window.location.href = data?.data?.ssoUrl;
    //       })
    //       .catch((err) => {
    //         handleToastErrorCatch(err, () => setLoginViaSsoBtnLoading(false));
    //       })
    //   }
    // }
    if (ssoLogin) {
      domain.current = "geminisolutions.com"
      if (domain.current && domain.current.includes('@')) {
        statementError("‘@’ is not allowed!");
        return;
      }
      setLoginViaSsoBtnLoading(true);
      authService.loginViaSso(domain.current)
        .then((data) => {
          window.location.href = data?.data?.ssoUrl;
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setLoginViaSsoBtnLoading(false));
        });
    }
    else {
      if (username.current.length === 0 || password.current.length === 0) {
        statementError("All fields are required !");
        return;
      }
      setLoginClicked(true);
      props
        .dispatch(login(username.current.trim(), md5Hex(password.current)))
        .then((data) => {
          getSuccessToast("Login Successful !");
          const redirectPath = sessionStorage.getItem("redirect");
          if (redirectPath) {
            if (
              redirectPath === "/" ||
              redirectPath === "/signup" ||
              redirectPath === "/login"
            ) {
              navigate("/autolytics/insights", { replace: true });
            } else {
              navigate(redirectPath, { replace: true });
            }
            sessionStorage.removeItem("redirect");
          } else {
            navigate("/autolytics/insights", { replace: true });
          }
        })
        .catch((err) => {
          if (err.message.toLowerCase() === "account verification required!") {
            getInfoToast("Account verification required!");
            navigate("/account-verification", {
              state: {
                firstName: err?.data?.firstName,
                lastName: err?.data?.lastName,
                username: err?.data?.username,
                email: err?.data?.email,
                hashedPassword: md5Hex(password.current),
                companyName: err?.data?.company,
              },
            });
          }
        })
        .finally(() => {
          setLoginClicked(false);
        });
    };
  }

  return (
    <>
      <Auth>
        <div className="d-flex card customSignup">
          <div className="card-body py-5 px-lg-5 px-md-5 px-sm-4 px-xs-2">
            <div className="text-center">
              <span className="titleSignup ">{loginViaSso ? "Login Via SSO" : "Welcome Back!"}</span>
              <br />
              <span className="subHeadingWrapper">
                {loginViaSso ? "Enter your company domain" : "Sign-in to continue to Jewel"}
              </span>
              <br />
            </div>
            {!(signInViaCredentials || loginViaSso) && (
              <div className="d-flex flex-column my-3">
                <Button
                  className=" btn themeBtn  text-center px-5 py-2 custom-btn"
                  loadingIcon={"pi pi-spin pi-spinner"}
                  label="Sign In Via Credentials"
                  onClick={() => { setSignInViaCredentials(true); }}
                />
                <div className="d-flex justify-content-center align-items-center my-3">
                  <hr style={{ flex: 1, borderTop: "2px solid #ccc", margin: "0 10px" }} />
                  <span style={{ backgroundColor: "#fff", padding: "0 10px", color: "#555" }}>OR</span>
                  <hr style={{ flex: 1, borderTop: "2px solid #ccc", margin: "0 10px" }} />
                </div>

                <Button
                  // onClick={() => { loginViaSso ? setSsoProceed(true) : setLoginViaSso(true) }}
                  onClick={(e) => loginUser(e, true)}
                  style={{ background: "3B82F6" }}
                  className="text-center px-5 py-2 custom-btn"
                  loadingIcon={"pi pi-spin pi-spinner"}
                  disabled={loginViaSsoBtnLoading || loginButtonLoading}
                  loading={loginViaSsoBtnLoading}
                  label={loginViaSso ? "Proceed" : "Login via SSO"}
                />
              </div>
            )}
            {(signInViaCredentials || loginViaSso) && (
              <form
                className="mt-3 formSignup"
                onSubmit={loginUser}
              >
                <>
                  {loginViaSso ? (
                    <div className="mt-2 inputfield">
                      <img src={domainIcon} />
                      <input
                        type="text"
                        className="form-control"
                        id="domainField"
                        onChange={(e) => (domain.current = e.target.value)}
                        placeholder="company.com"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="mt-2 inputfield">
                        <img src={userIcon} />
                        <input
                          type="text"
                          className="form-control"
                          id="usernameField"
                          onChange={(e) => (username.current = e.target.value)}
                          placeholder="Username"
                        />
                      </div>
                      <div className="mt-2 inputfield">
                        <img src={passwordIcon} />
                        <Password
                          id="passField"
                          onChange={(e) => (password.current = e.target.value)}
                          placeholder="Password"
                          toggleMask
                          feedback={false}
                        />
                      </div>
                    </>
                  )}
                </>

                <div class="d-flex">
                  <div className="text-center forgotPass">
                    <Link onClick={() => { setLoginViaSso(false); setSignInViaCredentials(false) }}>Go back</Link>
                  </div>
                  {!loginViaSso && (
                    <div className="text-center forgotPass ms-auto">
                      <Link to="/login/password/forgot">Forgot Password?</Link>
                    </div>
                  )}
                </div>

                <div className="loginBtnWrapper">
                  <div className="d-flex flex-column align-items-center justify-content-center custom-btn mt-1">
                    {!loginViaSso && (
                      <Button
                        disabled={loginClicked}
                        type="submit"
                        className=" btn themeBtn my-3 text-center px-5 py-2 custom-btn"
                        loadingIcon={"pi pi-spin pi-spinner"}
                        loading={loginButtonLoading}
                        label="Sign In"
                      />
                    )}
                    {/* {!signInViaCredentials && (
                  <Button
                    onClick={() => { loginViaSso ? setSsoProceed(true) : setLoginViaSso(true) }}
                    style={{ background: "3B82F6" }}
                    className="my-3 text-center px-5 py-2 custom-btn"
                    loadingIcon={"pi pi-spin pi-spinner"}
                    disabled={loginViaSsoBtnLoading || loginButtonLoading}
                    loading={loginViaSsoBtnLoading}
                    label={loginViaSso ? "Proceed" : "Login via SSO"}
                  />)} */}
                  </div>
                </div>
              </form>
            )}
            <div className="text-center forgotPass ms-auto">
              <Link to={"/signup"}>
                Not a member yet? Sign up now!
              </Link>
            </div>
          </div>
        </div>
      </Auth>
    </>
  );
};
function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}
export default connect(mapStateToProps)(Login);
