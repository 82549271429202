import "./ManualTCFile.css";
import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import httpService from "../../../services/http.service";
import {
  decodeParam,
  encodeParam,
  getToastMessage,
  toCapitalizeFirstChar,
} from "../../Helper/Helper";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import {
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import { MultiSelect } from "primereact/multiselect";

const ManualForm = forwardRef((props, ref) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        clearManualFormData,
        createManual,
      };
    },
    [{}]
  );

  const [tcName, setTcName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("New");
  const [selectedType, setSelectedType] = useState("Functional");
  const [selectedSeverity, setSelectedSeverity] = useState("Low");
  const [selectedPriority, setSelectedPriority] = useState("Low");
  const [selectedTestingCategory, setSelectedTestingCategory] = useState([]);
  const [description, setDescription] = useState("");
  const [testData, setTestData] = useState("");

  const [selectedAssignedToUser, setSelectedAssignedToUser] = useState("Pending");
  const [sprintName, setSprintName] = useState(null)

  const [selectedProject, setSelectedProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [variables, setVariables] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [TestcaseButtonLoading, setTestcaseButtonLoading] = useState(false);
  const [isSaveTestcaseButtonDisabled, setIsSaveTestcaseButtonDisabled] =
    useState(false);
  const [requirementId, setRequirementId] = useState("");

  useEffect(() => {
    const data =
      (props.type === "edit" || props.type === "editFromTestTool") &&
        props.editData
        ? (setTcName(props?.editData["name"]),
          setSelectedStatus(
            props?.editData?.["status"]
              ? toCapitalizeFirstChar(
                props?.editData?.["status"]
                  ?.toLowerCase()
                  ?.split("_")
                  .join(" ")
              )
              : "New"
          ),
          setProjectName(props?.editData["projectName"]),
          setSelectedType(
            props?.editData["manualTestcaseType"]
              ? toCapitalizeFirstChar(
                props?.editData?.["manualTestcaseType"]
                  ?.toLowerCase()
                  ?.split("_")
                  .join(" ")
              )
              : "Functional"
          ),
          setSelectedPriority(
            props?.editData["priority"]
              ? toCapitalizeFirstChar(
                props?.editData["priority"]
                  ?.toLowerCase()
                  ?.split("_")
                  .join(" ")
              )
              : "Low"
          ),
          setSelectedSeverity(
            props?.editData["severity"]
              ? toCapitalizeFirstChar(
                props?.editData["severity"]
                  ?.toLowerCase()
                  ?.split("_")
                  .join(" ")
              )
              : "Low"
          ),
          setDescription(props?.editData["description"]?.join("\n")),
          setSelectedTestingCategory(props?.editData?.automationType),
          setRequirementId(props?.editData["requirementId"]?.join("\n")),
          setTestData(props?.editData?.testData?.join("\n")),
          setSprintName(props?.editData?.sprintName),
          // .join("\n")
          setSelectedAssignedToUser(props?.editData["assignedTo"]))
        : setDataFields();
  }, []);

  // To check if the user has edited anything in the form. Disable "save Testcase" button if there is no change
  useEffect(() => {
    if (
      (props.type === "edit" || props.type === "editFromTestTool") &&
      props.editData
    ) {
      if (
        props?.editData?.name === tcName &&
        toCapitalizeFirstChar(
          props?.editData?.status?.toLowerCase().split("_").join(" ")
        ) === selectedStatus &&
        props?.editData?.projectName === projectName &&
        toCapitalizeFirstChar(
          props?.editData?.manualTestcaseType
            ?.toLowerCase()
            .split("_")
            .join(" ")
        ) === selectedType &&
        toCapitalizeFirstChar(
          props?.editData?.severity?.toLowerCase().split("_").join(" ")
        ) === selectedSeverity &&
        props?.editData?.description?.join("\n") === description &&
        toCapitalizeFirstChar(
          props?.editData?.priority?.toLowerCase().split("_").join(" ")
        ) === selectedPriority &&
        props?.editData?.testData?.join("\n") === testData &&
        props?.editData?.assignedTo === selectedAssignedToUser &&
        props?.editData?.sprintName === sprintName &&
        props?.editData?.automationType === selectedTestingCategory
      ) {
        setIsSaveTestcaseButtonDisabled(true);
      } else {
        setIsSaveTestcaseButtonDisabled(false);
      }
    }
  }, [
    tcName,
    selectedStatus,
    projectName,
    selectedType,
    selectedSeverity,
    description,
    selectedPriority,
    selectedAssignedToUser,
    sprintName,
    testData,
    selectedTestingCategory
  ]);

  const setDataFields = () => {
    if (!props?.f_id) {
      return null;
    }

    if (
      searchParams?.get("list") &&
      [...JSON.parse(decodeParam(searchParams.get("list")))].length > 0
    ) {
      let list = [...JSON.parse(decodeParam(searchParams.get("list")))];
      setProjectName(list?.[list.length - 1]?.["p_name"]);
      props.getUsers(props.p_id);
      return;
    }

    setProjectName(props?.p_name);
    props.getUsers(props.p_id);
  };
  const userOptionTemplate = (option) => {
    return (
      <>
        <div>
          <span>{`${option.Name} ( ${option.Username} )`}</span>
        </div>
      </>
    );
  };

  const clearManualFormData = (e) => {
    setTcName("");
    setSelectedStatus("New");
    setSelectedProject(null);
    setSelectedType("Functional");
    setSelectedPriority("Low");
    setSelectedSeverity("Low");
    setDescription("");
    setSelectedTestingCategory([])
    setSprintName("")
    setSelectedAssignedToUser("Pending");

    props.setEditData && props.setEditData(null);
    props.setEditTcId && props.setEditTcId(null);
  };

  function updateBreadcrumbsAndSearchParams() {
    const updatedBreadList = props.breadList?.map((item) => {
      if (item?.p_id == props.p_id && item?.mtc_id == props.mtc_id) {
        return { ...item, name: tcName };
      }
      return item;
    });

    if (updatedBreadList) {
      // update breadCrumbs
      props.setBreadList(updatedBreadList);

      // update SearchParams
      if (
        props.mtc_id == searchParams.get("mtc_id") &&
        props.p_id == searchParams.get("p_id") &&
        searchParams.get("list")
      ) {
        const newListParam = encodeParam(JSON.stringify(updatedBreadList));
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("list", newListParam);
        setSearchParams(newSearchParams);
      }
    }
  }

  const createManual = (e) => {
    e?.preventDefault();

    if (
      tcName.length > 0 &&
      selectedStatus &&
      selectedType &&
      selectedPriority &&
      selectedSeverity &&
      description.length > 0
    ) {
      let payload = {
        folderId: props.f_id,
        name: tcName,
        pid:
          props.type === "create"
            ? props.f_id
              ? props.p_id
              : selectedProject
            : props.editData["pid"],
        status: selectedStatus.toUpperCase().split(" ").join("_"),
        manualTestcaseType: selectedType.toUpperCase().split(" ").join("_"),
        description: description.split("\n"),
        // requirementId: requirementId?.split("\n"),
        priority: selectedPriority.toUpperCase(),
        severity: selectedSeverity.toUpperCase(),
        assignedTo: selectedAssignedToUser,
        variables: variables,
        sprintName: sprintName?.length > 0 ? sprintName : null,
        automationType: selectedTestingCategory,
        type: selectedType.toUpperCase().split(" ").join("_"),
        testData: testData?.split("\n"),
      };

      if (props.type === "create") {
        setTestcaseButtonLoading(true);
        httpService
          .createNewManual(payload)
          .then((data) => {
            getToastMessage(data);
            if (props.userCompanyName?.toLowerCase() === "individual") {
              props.getFreeUserQuota();
            }
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              clearManualFormData();
              props.setManualDialog(false);
            }
            setTestcaseButtonLoading(false);
          })
          .catch((err) => {
            handleToastErrorCatch(err, () => {
              clearManualFormData();
              setTestcaseButtonLoading(false);
            });
          });
      } else if (props.type === "edit") {
        payload["isolatedVersionId"] = props?.editTcId
          ? props?.editTcId
          : props?.mtc_id;
        setTestcaseButtonLoading(true);
        httpService
          .editManual(payload)
          .then((data) => {
            getToastMessage(data);
            if (
              data["operation"] &&
              data["operation"].toLowerCase() === "success"
            ) {
              !props?.editTcId && updateBreadcrumbsAndSearchParams();
              props.setManualDialog(false);
              props?.setEditTcId(null);
            }
            setTestcaseButtonLoading(false);
          })
          .catch((err) => {
            handleToastErrorCatch(err, () => setTestcaseButtonLoading(false));
          });
      } else {
        payload["s_id"] = props.editData["s_id"];
        payload["framework"] = "MANUAL";
        return payload;
      }
    } else {
      statementError("Fill out mandatory fields !");
    }
  };


  const createManualForm = () => {
    return (
      <div className="p-3">
        <form>
          <div>
            <div className="row mb-3 mt-1">
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Testcase Name<sup> *</sup>
                </div>
                <div className="manualFormInputs">
                  <InputText
                    type={"text"}
                    value={tcName}
                    // disabled={props.type === "edit"}
                    onChange={(e) => setTcName(e.target.value)}
                    className="w-100"
                    placeholder={"Testcase Name"}
                    disabled={TestcaseButtonLoading}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Project Name<sup> *</sup>
                </div>
                <div className="manualFormInputs">
                  {!props.mtc_id ? (
                    props.f_id ? (
                      <InputText
                        type={"text"}
                        value={projectName}
                        disabled
                        className="w-100"
                      />
                    ) : (
                      <Dropdown
                        className="dialog-dropdown px-3 w-100"
                        options={props.projectsWithAccess}
                        optionValue="P ID"
                        optionLabel="Project Name"
                        value={selectedProject}
                        onChange={(e) => {
                          setSelectedProject(e.value);
                          props.getUsers(e.value);
                          setSelectedAssignedToUser("pending");
                        }}
                        filter
                        placeholder="Select Project"
                        maxSelectedLabels={1}
                        disabled={TestcaseButtonLoading}
                      />
                    )
                  ) : (
                    <InputText
                      type={"text"}
                      value={projectName}
                      disabled
                      className="w-100"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div>
                  <div className="ms-1 mb-1 mt-1 pDetails-heading">
                    Assigned To
                  </div>
                  <div className="manualFormInputs">
                    {props?.users?.length > 0 ? (
                      <Dropdown
                        value={selectedAssignedToUser}
                        onChange={(e) => setSelectedAssignedToUser(e.value)}
                        options={props.users}
                        optionLabel="Name"
                        optionValue="Username"
                        virtualScrollerOptions={{ itemSize: 38 }}
                        itemTemplate={userOptionTemplate}
                        placeholder="Select User"
                        className="dialog-dropdown w-100"
                        disabled={TestcaseButtonLoading}
                      />
                    ) : (
                      <Dropdown
                        className="dialog-dropdown w-100"
                        options={[]}
                        emptyMessage="No User(s) Found"
                        placeholder="Select User"
                        disabled={TestcaseButtonLoading}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 mt-1">
              <div className="col-md-4 col-12">
                <div>
                  <div className="ms-1 mb-1 mt-1 pDetails-heading">
                    Type<sup> *</sup>
                  </div>
                  <div className="manualFormInputs">
                    <Dropdown
                      className="dialog-dropdown w-100"
                      // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                      options={[
                        "Functional",
                        "Performance",
                      ]}
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.value)}
                      placeholder="Select Type"
                      maxSelectedLabels={1}
                      disabled={TestcaseButtonLoading}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div>
                  <div className="ms-1 mb-1 mt-1 pDetails-heading">
                    Severity<sup> *</sup>
                  </div>
                  <div className="manualFormInputs">
                    <Dropdown
                      className="dialog-dropdown w-100"
                      // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                      options={["Critical", "High", "Medium", "Low"]}
                      value={selectedSeverity}
                      onChange={(e) => setSelectedSeverity(e.value)}
                      placeholder="Select Severity"
                      maxSelectedLabels={1}
                      disabled={TestcaseButtonLoading}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div>
                  <div className="ms-1 mb-1 mt-1 pDetails-heading">
                    Priority<sup> *</sup>
                  </div>
                  <div className="manualFormInputs">
                    <Dropdown
                      className="dialog-dropdown w-100"
                      // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                      options={["Critical", "High", "Medium", "Low"]}
                      value={selectedPriority}
                      onChange={(e) => setSelectedPriority(e.value)}
                      placeholder="Select Priority"
                      maxSelectedLabels={1}
                      disabled={TestcaseButtonLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12">
                <div className="row mt-1">
                  <div className="col-6">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Status<sup> *</sup>
                    </div>
                    <div className="manualFormInputs">
                      <Dropdown
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={[
                          "New",
                          "Under Review",
                          "Approved",
                          "Decommissioned",
                          "In Prod",
                        ]}
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.value)}
                        placeholder="Select Status"
                        maxSelectedLabels={1}
                        disabled={TestcaseButtonLoading}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Testing Classification
                    </div>
                    <div className="manualFormInputs">
                      <MultiSelect
                        className="dialog-dropdown w-100"
                        // style={{ minWidth: "12vw", maxWidth: "12vw" }}
                        options={[
                          "REGRESSION",
                          "SMOKE",
                        ]}
                        value={selectedTestingCategory}
                        onChange={(e) => setSelectedTestingCategory(e.value)}
                        placeholder="Select Category"
                        maxSelectedLabels={1}
                        disabled={TestcaseButtonLoading}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-1">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Sprint Name
                    </div>
                    <div className="manualFormInputs">
                      <InputText
                        type={"text"}
                        value={sprintName}
                        // disabled={props.type === "edit"}
                        onChange={(e) => setSprintName(e.target.value)}
                        className="w-100"
                        placeholder={"Sprint Name"}
                        disabled={TestcaseButtonLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Description<sup> *</sup>
                </div>
                <div className="">
                  <InputTextarea
                    value={description}
                    id="desc"
                    className="w-100 manualFormInputs"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    rows={5}
                    autoResize
                    disabled={TestcaseButtonLoading}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">Test Data</div>
                <div className="">
                  <InputTextarea
                    value={testData}
                    id="testdata"
                    className="w-100 manualFormInputs"
                    onChange={(e) => {
                      setTestData(e.target.value);
                    }}
                    rows={5}
                    autoResize
                    disabled={TestcaseButtonLoading}
                  />
                </div>
              </div>
            </div>
            {!(props.type === "editFromTestTool") ? (
              <div className="mt-4 text-center">
                <Button
                  label={
                    props.type === "edit" ? "Save Testcase" : "Create Testcase"
                  }
                  type="submit"
                  className="btn-success btn themeBtn mx-2 p-2 mt-2"
                  onClick={(e) => createManual(e)}
                  disabled={isSaveTestcaseButtonDisabled}
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  loading={TestcaseButtonLoading}
                  iconPos="right"
                />
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  };
  return <div className="card reqFormCard">{createManualForm()}</div>;
});

export default ManualForm;
