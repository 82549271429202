import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { loadingData } from "../../../Helper/Helper";

const DeleteDialog = ({
    header,
    deleteDialog,
    onHideDeleteDialog,
    toDeleteName,
    toDeleteId,
    toDeleteType,
    deleteFolder,
    deleteFile,
    killExeDialog,
    hookType
}) => {

    const [deleteLoading, setDeleteLoading] = useState(false);

    return (
        <Dialog
            blockScroll={true}
            draggable={false}
            header={header}
            visible={deleteDialog}
            onHide={() => {
                onHideDeleteDialog();
            }}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
        >
            {deleteLoading ? (
                <div className="py-5">
                    {loadingData(!killExeDialog ? "Deleting " + hookType === "useState" ? toDeleteName : toDeleteName.current : "Processing request to abort execution")}
                </div>
            ) : (
                <>
                    <div className="text-center mt-3 py-2 mx-3">
                        <h5>
                            {toDeleteType?.current === "folder" && <span className="text-danger fw-bold">All Folders/Testcases inside will be deleted too !! <br /> <br /></span>}
                            {killExeDialog && <span className="text-danger fw-bold"> This will result in all test cases and suite being in the ERROR state ! ! <br /> <br /></span>}
                            {!killExeDialog ? <span>Are you Sure you want to delete : {hookType === "useState" ? toDeleteName : toDeleteName.current} ?</span> : <span>Are you sure you want to kill/abort this execution ?</span>}
                        </h5>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <div
                            onClick={() => {
                                toDeleteType?.current === "folder" ?
                                    deleteFolder(toDeleteId.current, setDeleteLoading)
                                    : deleteFile(toDeleteId.current, setDeleteLoading)
                            }}
                            className="btn btn-success"
                        >
                            <FontAwesomeIcon
                                className="ms-1 tabIcon me-1"
                                icon={faCheck}
                            />
                            Yes
                        </div>
                        <div
                            onClick={() => {
                                onHideDeleteDialog();
                            }}
                            className="btn btn-danger mx-2"
                        >
                            <FontAwesomeIcon
                                className="ms-1 tabIcon me-1"
                                icon={faXmark}
                            />
                            No
                        </div>
                    </div>
                </>
            )}
        </Dialog>
    )
}

export default DeleteDialog