import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faCircleUser,
} from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import "./CrudProject.css";
import httpService from "../../../services/http.service";
import { getToastMessage } from "../../Helper/Helper";
import { handleToastErrorCatch } from "../../Helper/ToastHelper";
import { faDoorOpen, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const CrudProject = ({ props }) => {
  const isAdmin = props.rowData?.Access?.value?.toLowerCase() === "admin";
  const company = props?.rowData?.["Company Name"]?.sortValue;
  const username = useSelector((state) => state.auth.userProfile.username);
  const removalRequest = props?.rowData?.removalInfo?.removalRequest;
  const [projectRemovalRequestLoading, setProjectRemovalRequestLoading] =
    useState(false);
  return (
    <>
      <div className="crud-icons">
        <FontAwesomeIcon
          className={`crudIcons mx-2 ${isAdmin ? "notDisabledAdmin" : "disabledIcon"
            }`}
          icon={faPenToSquare}
          onClick={(e) => {
            if (isAdmin) {
              props["edit_project"]["editProject"](e, props.val);
            }
          }}
          title={`${isAdmin ? "Edit Project Details" : "No Admin Access"}`}
        />

        {/* <button className="adminAction"> */}
        <FontAwesomeIcon
          className={`crudIcons mx-2 ${isAdmin ? "notDisabledAdmin" : "disabledIcon"
            }`}
          icon={faTrashCan}
          onClick={(e) => {
            if (isAdmin) {
              props["delete_project"]["deleteProject"](
                e,
                props.val,
                props.rowData["Project Name"],
                props.rowData["Access"]
              );
            }
          }}
          title={`${isAdmin ? "Delete Project" : "No Admin Access"}`}
        />
        {!isAdmin && (
          <FontAwesomeIcon
            icon={projectRemovalRequestLoading ? faSpinner : faDoorOpen}
            title={`${removalRequest ? "Request Pending" : "Exit from Project"
              }`}
            className={`${!removalRequest ? "notDisabledAdmin" : "disabledIcon"
              } ${projectRemovalRequestLoading ? "fa-spin" : ""}`}
            onClick={(e) => {
              let payload = {
                pid: props?.p_id,
                username: username,
              };

              if (!removalRequest && !projectRemovalRequestLoading) {
                setProjectRemovalRequestLoading(true);
                httpService
                  .removeProjectUser(payload)
                  .then((data) => {
                    getToastMessage(data);
                  })
                  .catch((err) => {
                    handleToastErrorCatch(err);
                  })
                  .finally(() => setProjectRemovalRequestLoading(false));
              }
            }}
          />
        )}
        {/* </button> */}
        {props?.userCompanyName?.toLowerCase() !== "individual" &&
          <FontAwesomeIcon
            className={`crudIcons mx-2 ${isAdmin ? "notDisabledAdmin" : "disabledIcon"
              }`}
            icon={faCircleUser}
            onClick={(e) => {
              if (isAdmin) {
                props["edit_roles"]["editProjectAccess"](
                  e,
                  props?.val,
                  props?.rowData?.["Project Name"]?.["value"],
                  props?.rowData?.["Company Name"]?.sortValue
                );
              }
            }}
            title={`${isAdmin ? "Edit Access" : "No Admin Access"}`}
          />}
      </div>
    </>
  );
};

export default CrudProject;
