import { useDispatch, useSelector } from "react-redux";
import {
  AVAILABLE_SUITE,
  CLEAR_AVAILABLE_SUITE,
  CLEAR_PROJECT_DATA_TESTTOOL,
  PROJECT_DATA_TESTTOOL,
} from "../../../../actions/type";
import httpService from "../../../../services/http.service";
import {
  copyToClipboard,
  createColumns,
  createFilters,
  getErrorMessage,
  getToastMessage,
  logPageTitleForGA,
} from "../../../Helper/Helper";
import { useEffect } from "react";
import { connectSocket } from "../../../Helper/SocketHelper";
import {
  handleToastErrorCatch,
  statementError,
} from "../../../Helper/ToastHelper";
import { useState } from "react";
import { useRef } from "react";
import { DataTable } from "primereact/datatable";
import SkeletonUtility from "../../../UI/Skeleton/SkeletonUtility";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import ExecuteSuite from "./ExecuteSuite";
import VersionControl from "./../VersionControl";
import ShareVia from "./../ShareVia";
import Scheduler from "./../Scheduler/Scheduler";
import NoAccess from "../../../UI/NoAccess/NoAccess";
import TestcasePillRevamp from "./TestcasePillRevamp";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import noTableData from "../../../../assets/noTableData.svg";
import NoData from "../../../UI/NoData/NoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

const ELab = () => {
  const dispatch = useDispatch();
  const [showCreateSuite, setShowCreateSuite] = useState(false);
  const [dataMain, setDataMain] = useState(null);
  const getSuite = useRef(false);
  const [showTestcase, setShowTestcase] = useState(false);
  const testcaseref = useRef();
  const [noAccessFile, setNoAccessFile] = useState(false);
  const testcasePill = useRef();
  const [suiteId, setSuiteId] = useState(null);
  const [selectedSuite, setSelectedSuite] = useState(null);
  const firstUpdate = useRef(true);
  const pidArray = useRef(null);
  const suiteConnection = useRef(null);
  const [createNewSuiteLoading, setCreateNewSuiteLoading] = useState(false);
  const [createSuiteButtonLoading, setCreateSuiteButtonLoading] =
    useState(false);
  const [projectsWithAccess, setProjectsWithAccess] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [suiteName, setSuiteName] = useState(null);
  const [elabFilters, setElabFilters] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);
  const role = useSelector((state) => state.auth.userProfile.role);
  const [freeUserStats, setFreeUserStats] = useState(null);
  const userCompanyName = useSelector(
    (state) => state.auth.userProfile.company
  );

  useEffect(() => {
    let title = "Jewel | ELab";
    document.title = title;
    logPageTitleForGA(title);
    connectSocket(
      "execution",
      "SUITE/private",
      suiteConnection,
      notifySuiteClient
    );
    httpService
      .getProjectRole()
      .then((data) => {
        let pids = [];
        data.data.map((roles) => pids.push(roles["pid"]));
        getErrorMessage(data);
        return Promise.resolve(pids);
      })
      .then((data) => {
        if (data && data.length > 0) {
          httpService.getProjectData(data).then((res) => {
            dispatch({
              type: PROJECT_DATA_TESTTOOL,
              payload: res,
            });
            if (res?.length > 0) {
              let pids = res?.map((data) => data.pid);
              pidArray.current = pids;
            }
            getSuite.current = true;
            return Promise.resolve(res);
          });
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.operation?.toLowerCase() === "info" &&
          err?.response?.data?.suboperation
        ) {
          setNoAccessFile(true);
          if (userCompanyName?.toLowerCase() !== "individual") {
            dispatch({
              type: PROJECT_DATA_TESTTOOL,
              payload: err["response"]["data"],
            });
          }
        }
        if (userCompanyName?.toLowerCase() !== "individual") {
          handleToastErrorCatch(err);
        }
      })
      .finally(() => {
        getSuiteData();
      });

    if (userCompanyName?.toLowerCase() === "individual") {
      httpService.getFreeUserQuota()
        .then(data => {
          setFreeUserStats(data?.data)
        })
        .catch(err => handleToastErrorCatch(err));
    }

    return () => {
      dispatch({
        type: CLEAR_PROJECT_DATA_TESTTOOL,
      });
      dispatch({
        type: CLEAR_AVAILABLE_SUITE,
      });
      suiteConnection?.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (dataMain) {
      let finalHeaders = [];
      finalHeaders = [...dataMain?.headers, ...dataMain?.optionalHeaders];
      if (Object.keys(elabFilters)?.length === 0) {
        const filterData = dataMain?.headers ? createFilters(finalHeaders) : {};
        setElabFilters(filterData);
      }
    }
  }, [dataMain]);
  const notifySuiteClient = (e) => {
    if (e["body"]) {
      let wsBody = JSON.parse(e["body"]);
      if (wsBody["pid"]) {
        if (pidArray.current && pidArray.current?.includes(wsBody["pid"])) {
          getSuiteData();
        }
      }
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (testcaseref.current) {
      testcaseref.current.click();
    }
  }, [showTestcase]);

  const getSuiteData = (e) => {
    httpService
      .getAvailableTestToolSuites()
      .then((data) => {
        dispatch({
          type: AVAILABLE_SUITE,
          payload: data.data,
        });
        setDataMain(data["data"]);
        getErrorMessage(data);
        // setSuiteLoading(false);
      })
      .catch((err) => {
        if (getSuite.current) {
          handleToastErrorCatch(
            err
            // , () => setSuiteLoading(false)
          );
        }
      });
  };

  const createSuite = () => {
    if (selectedProject && suiteName && suiteName?.length > 0) {
      setCreateSuiteButtonLoading(true);
      let payload = {
        p_id: selectedProject,
        report_name: suiteName,
      };
      httpService
        .createSuiteTestTool(payload)
        .then((data) => {
          getToastMessage(data);
          setShowCreateSuite(false);
          setSuiteName(null);
          setSelectedProject(null);
          setCreateSuiteButtonLoading(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => { setCreateSuiteButtonLoading(false) });
        });
    } else {
      statementError("All fields are required !");
    }
  };

  const getProjects = () => {
    setCreateNewSuiteLoading(true);
    httpService
      .getAllProjectsWithAccess()
      .then((data) => {
        setProjectsWithAccess(data?.data);
        getErrorMessage(data);
        setCreateNewSuiteLoading(false);
        setShowCreateSuite(true);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setCreateNewSuiteLoading(false));
      });
  };

  const addTestcase = (e, val, p_id, rowData) => {
    setShowTestcase(true);
    setSuiteId(val);

    dataMain?.data &&
      dataMain?.data?.filter(
        (rowDataIndex) =>
          rowDataIndex?.["Suite ID"].value === val &&
          setSelectedSuite(rowDataIndex)
      );

    if (testcaseref.current) {
      testcaseref.current.click();
    }
  };

  const onColumnToggle = (e) => {
    setSelectedColumns(e.value);
  };

  const header = (
    <div className="d-flex">
      {dataMain?.optionalHeaders && (
        <div className="" style={{ minHeight: "2vh" }}>
          <MultiSelect
            placeholder="More Detail(s)"
            value={selectedColumns}
            options={dataMain?.optionalHeaders}
            onChange={(e) => onColumnToggle(e)}
            maxSelectedLabels={1}
            style={{ width: "15vw" }}
          />
        </div>
      )}
      <div className="d-flex align-items-center mx-auto">Suite(s)</div>
    </div>
  );

  return !noAccessFile ? (
    <div className="container-fluid reqSection mx-2">
      <div className="d-flex flex-wrap align-items-center pt-2 mx-2">
        <div className=" pb-1">
          <div className="headerTest">
            ELab
            <span>
              <FontAwesomeIcon
                className="actionBtn ms-2"
                icon={faCopy}
                title="Copy Link"
                onClick={(e) =>
                  copyToClipboard(
                    window.location.origin + "/" + window.location.hash
                  )
                }
              />
            </span>
          </div>
          <div className="breadLinkDisabled my-2 mt-3"> Execution Lab</div>
        </div>
        <div className="ms-auto mb-5 me-4">
          {role.toString().toUpperCase() !== "SUPER_ADMIN" && !showTestcase && (
            <Button
              label="Create Suite"
              className="btn-success btn themeBtn mx-2 p-2 mt-1"
              onClick={(e) => getProjects()}
              icon={
                createNewSuiteLoading ? "pi pi-spin pi-spinner me-2" : "pi pi-plus me-2"
              }
              iconPos="right"
              loading={createNewSuiteLoading}
            />
          )}
        </div>
      </div>
      <Dialog
        blockScroll={true}
        draggable={false}
        header={`Create Suite`}
        visible={showCreateSuite}
        onHide={() => {
          setShowCreateSuite(false);
          setCreateSuiteButtonLoading(false);
          setSuiteName(null);
          setSelectedProject(null);
        }}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "60vw" }}
      >
        <>
          <div className="row d-flex mt-3 mx-3">
            <div className="col-md-6 col-12">
              <div className="mb-1 dropdown-heading mt-2">
                Suite Name<sup> *</sup>
              </div>
              <div className="me-2">
                <InputText
                  // style={{ minWidth: "20vw", maxWidth: "20vw" }}
                  type="text"
                  value={suiteName}
                  className="w-100"
                  placeholder={"Suite Name"}
                  onChange={(e) => setSuiteName(e.target.value)}
                  disabled={createSuiteButtonLoading}
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="mb-1 dropdown-heading mt-2">
                Project Name <sup> *</sup>
              </div>
              <Dropdown
                className="dialog-dropdown px-3 w-100"
                options={projectsWithAccess}
                optionValue="P ID"
                optionLabel="Project Name"
                value={selectedProject}
                onChange={(e) => {
                  setSelectedProject(e.value);
                }}
                filter
                placeholder="Select Project"
                maxSelectedLabels={1}
                disabled={createSuiteButtonLoading}
              />
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-end">
            {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
              <Button
                label="Create Suite"
                className="btn-success btn themeBtn mx-2 p-2 mt-1"
                onClick={(e) => createSuite()}
                icon={createSuiteButtonLoading && "pi pi-spin pi-spinner me-2"}
                iconPos="right"
                loading={createSuiteButtonLoading}
              />
            )}
          </div>
        </>
      </Dialog>
      <ul
        className="nav nav-pills ms-2 pt-1 mb-3"
        id={"pills-tab"}
        role="tablist"
      >
        <li className="nav-item mr-1" role="presentation">
          <div
            className="fontSizeGeneral nav-link extentPill active"
            id={"pills-suite-tab"}
            data-toggle="pill"
            data-target={"#pills-suite"}
            type="button"
            role="tab"
            aria-selected="true"
            onClick={() => setShowTestcase(false)}
          >
            Suite
          </div>
        </li>
        {showTestcase && (
          <>
            <li className="nav-item ms-1" role="presentation">
              <div
                ref={testcaseref}
                className={`fontSizeGeneral nav-link extentPill`}
                id={"pills-testcase-tab"}
                data-toggle="pill"
                data-target={"#pills-testcase"}
                type="button"
                role="tab"
                aria-selected="false"
              >
                Testcases
              </div>
            </li>
          </>
        )}
      </ul>
      <div className="tab-content ms-2 me-4 pb-3" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id={"pills-suite"}
          role="tabpanel"
          aria-labelledby={"pills-suite-tab"}
        >
          {dataMain ? (
            <div className="mt-2">
              <DataTable
                header={header}
                reorderableColumns
                onFilter={(e) => {
                  setElabFilters(e.filters);
                }}
                resizableColumns
                stripedRows
                columnResizeMode="expand"
                value={dataMain ? dataMain["data"] : null}
                paginator
                rows={25}
                dataKey="suiteTable"
                filters={elabFilters}
                filterDisplay="menu"
                responsiveLayout="scroll"
                className="test-stepTable"
                emptyMessage={<NoData />}
                currentPageReportTemplate="Total {totalRecords} Suite(s) Found"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
                rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                scrollHeight={"55vh"}
                removableSort
              >
                {dataMain?.headers &&
                  createColumns(
                    dataMain["headers"],
                    true,
                    true,
                    dataMain["data"],
                    false,
                    null,
                    {
                      edit_suite: addTestcase,
                      edit_suite_icon: faSquarePlus,
                    },
                    {
                      git_add: true,
                      git_update: true,
                      email_add: true,
                      email_update: true,
                      scheduler: true,
                      execute_suite: true,
                    },
                    {
                      git_add: VersionControl,
                      git_update: VersionControl,
                      email_add: ShareVia,
                      email_update: ShareVia,
                      scheduler: Scheduler,
                      execute_suite: ExecuteSuite,
                    },
                    {
                      git_add_props: {
                        isActive: false,
                        setShowTestcase: setShowTestcase,
                        addTestcase: addTestcase,
                        freeUserStats: freeUserStats
                      },
                      git_update_props: {
                        isActive: true,
                        setShowTestcase: setShowTestcase,
                        addTestcase: addTestcase,
                        freeUserStats: freeUserStats
                      },
                      email_add_props: {
                        isActive: false,
                        setDataMain: setDataMain,
                      },
                      email_update_props: {
                        isActive: true,
                        setDataMain: setDataMain,
                      },
                      scheduler_props: {
                        setDataMain: setDataMain,
                        freeUserStats: freeUserStats
                      },
                      execute_suite_props: {
                        dataMain: dataMain,
                        freeUserStats: freeUserStats
                      },
                    }
                  )}
                {dataMain?.optionalHeaders &&
                  createColumns(selectedColumns, true, true, dataMain?.data)}
              </DataTable>
            </div>
          ) : (
            <SkeletonUtility
              header={true}
              headerValue={"Suite(s)"}
              paginator={false}
            />
          )}
        </div>
        <div
          ref={testcasePill}
          className="tab-pane fade"
          id={"pills-testcase"}
          role="tabpanel"
          aria-labelledby={"pills-testcase-tab"}
        >
          {suiteId && (
            <TestcasePillRevamp
              key={suiteId}
              sid={suiteId}
              selectedSuite={selectedSuite}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <NoAccess />
  );
};

export default ELab;
