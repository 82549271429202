import "./Admin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faUnlock,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import httpService from "../../../services/http.service";
import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Messages } from "primereact/messages";
import {
  ADMIN_AVAILABLE_PROJECTS,
  CLEAR_ADMIN_AVAILABLE_PROJECTS,
} from "../../../actions/type";
import {
  getErrorMessage,
  createColumns,
  createFilters,
  getToastMessage,
  loadingData,
  logPageTitleForGA,
  getInfoMessage,
} from "../../Helper/Helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import CrudProject from "./CrudProject";
import AccessRequest from "./AccessRequest";
import AdminAccessApproval from "./AdminAccessApproval/AdminAccessApproval";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import { Badge } from "primereact/badge";
import EditUserAccess from "./EditUserAccess";
import { over } from "stompjs";
import ChangeRole from "./ChangeRole";
import AddUser from "./AddUser";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import CompanyAdmin from "./CompanyAdmin";
import BreadCrumb from "../../UI/BreadCrumb/BreadCrumb";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import SkeletonUtility from "../../UI/Skeleton/SkeletonUtility";
import { connectSocket } from "../../Helper/SocketHelper";
import {
  handleToastErrorCatch,
  statementError,
} from "../../Helper/ToastHelper";
import NoData from "../../UI/NoData/NoData";
import ProjectAccessAdmin from "./ProjectAccessAdmin";
import ProjectRemovalApproval from "./ProjectRemovalApproval";
import { Message } from 'primereact/message'
let stompClient = null;

const Admin = () => {
  const dispatch = useDispatch();
  const [adminFilters, setAdminFilters] = useState({});
  const [activeTab, setActiveTab] = useState("newRequest");
  const [userFilters, setUserFilters] = useState({});
  const [pendingRequestFilters, setPendingRequestFilters] = useState({});
  const [adminProjects, setAdminProjects] = useState(null);
  const [requestAccessDialog, setRequestAccessDialog] = useState(false);
  const [selectedAccess, setSelectedAccess] = useState("");
  const [allProjectsName, setAllProjectsName] = useState([]);
  const [selectedProjectForAccess, setSelectedProjectForAccess] = useState([]);
  const [createProjectDialog, setCreateProjectDialog] = useState(false);
  const [accessApprovalDialog, setAccessApprovalDialog] = useState(false);
  const [projectAccessApprovalDialog, setProjectAccessApprovalDialog] =
    useState(false);
  const [accessApprovalData, setAccessApprovalData] = useState(null);
  const [counter, setCounter] = useState(null);
  const [projectCounter, setProjectCounter] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [env, setEnv] = useState([]);
  const [projectFlow, setProjectFlow] = useState([
    "New",
    "Under Review",
    "Approved",
    "Decommissioned",
    "In Prod",
  ]);
  const [projectDescription, setProjectDescription] = useState("");
  const [selectedDevs, setSelectedDevs] = useState([]);
  const [selectedPOC, setSelectedPOC] = useState([]);
  const [qaLead, setQaLead] = useState([]);
  const [qaTeam, setQaTeam] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [editProjectDialog, setEditProjectDialog] = useState(false);
  const [p_id, setP_id] = useState(null);
  const [sameCompanyUsers, setSameCompanyUsers] = useState([]);
  const toDeletePName = useRef(null);
  const toDeletePId = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editAccessDialog, setEditAccessDialog] = useState(false);
  const [projectAccessUsers, setProjectAccessUsers] = useState({});
  const existingHeaders = useRef([]);
  const toggleHeaders = useRef([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectAllDev, setSelectAllDev] = useState(false);
  const [selectAllMembers, setSelectAllMembers] = useState(false);
  const [selectAllPOC, setSelectAllPOC] = useState(false);
  const [selectAllTeam, setSelectAllTeam] = useState(false);
  const [selectAllLead, setSelectAllLead] = useState(false);
  const [isProjectNameValid, setIsProjectNameValid] = useState(false);
  const [projectNameAlreadyExists, setProjectNameAlreadyExists] =
    useState(false);
  const editAccessDialogTitle = useRef("");
  const [blockedUsersDialog, setBlockedUsersDialog] = useState(false);
  const [blockedUsersData, setBlockedUsersData] = useState(false);
  const [blockedFilters, setBlockedFilters] = useState({});
  const connectionUser = useRef(null);
  const connectionProject = useRef(null);
  const [requestAccessButtonLoading, setRequestAcessButtonLoading] =
    useState(false);
  const [pendingRequestData, setPendingRequestData] = useState(null);
  const company = useSelector((state) => state.auth.userProfile.socket);
  const role = useSelector((state) => state.auth.userProfile.role);
  const [showProjectNameMessage, setShowProjectNameMessage] = useState(false);
  const [requestAccessDialogButtonLoading, setRequestAcessDialogButtonLoading] =
    useState(false);
  const [isCreateProjectButtonLoading, setIsCreateProjectButtonLoading] =
    useState(false);
  const [projectRemovalApprovalDialog, setProjectRemovalApprovalDialog] =
    useState(false);
  const [disableBulkButtons, setDisableBulkButtons] = useState([]);
  const [projectAccessApprovalLoading, setProjectAccessApprovalLoading] =
    useState(false);
  const [projectAccessApprovalData, setProjectAccessApprovalData] =
    useState(null);
  const [isApproveAllButtonLoading, setIsApproveAllButtonLoading] =
    useState(false);
  const [isRejectAllButtonLoading, setIsRejectAllButtonLoading] =
    useState(false);
  const [projectRemovalApprovalLoading, setProjectRemovalApprovalLoading] =
    useState(false);
  const [projectRemovalApprovalData, setProjectRemovalApprovalData] =
    useState(null);
  const [projectRemovalCounter, setProjectRemovalCounter] = useState(null);
  const [bulkActionTrigger, setBulkActionTrigger] = useState(false);
  const [selectedProjectTypes, setSelectedProjectTypes] = useState(['APPLICATION']);
  const [freeUserStats, setFreeUserStats] = useState(null);
  const multiSelectRef = useRef(null);
  const userCompanyName = useSelector(
    (state) => state.auth.userProfile.company
  );

  useEffect(() => {
    if (selectedUser?.length > 0) {
      setDisableBulkButtons(selectedUser);
    } else {
      setDisableBulkButtons([]);
    }
  }, [selectedUser]);
  const crudStatus = (data) => {
    return (<span className={`px-3 py-1 badgePill ${data?.props?.val?.toLowerCase() === "active" ? "low-color" : "medium-color"}`}>{data?.props?.val}</span>)
  }
  const getFreeUserQuota = () => {
    httpService.getFreeUserQuota()
      .then(data => {
        setFreeUserStats(data?.data)
      })
      .catch(err => handleToastErrorCatch(err));
  }

  useEffect(() => {
    let title = "Jewel | Admin";
    document.title = title;
    logPageTitleForGA(title);
    getUserProjects();
    getRequestAccData();
    if (userCompanyName?.toLowerCase() === "individual") {
      getFreeUserQuota();
    }
    !(role?.toLowerCase() === "super_admin") && getApprovalRequests();
    role.toLowerCase() === "admin" && getBlockedUsers();
    if (!(role.toLowerCase() === "user")) {
      getProjectPendingRequests();
      getProjectRemovalPendingRequests();
    }
    connectSocket(
      "user",
      company.toUpperCase() + "/private",
      connectionUser,
      notifyClient
    );
    connectSocket(
      "project",
      company.toUpperCase() + "/private",
      connectionProject,
      notifyClient
    );
    document.addEventListener("onAddUser", getProjectUsersFn);
    document.addEventListener("onUserBlock", getBlockedUsers);

    return () => {
      dispatch({
        type: CLEAR_ADMIN_AVAILABLE_PROJECTS,
      });
      connectionUser?.current?.unsubscribe();
      connectionProject?.current?.unsubscribe();
      document.removeEventListener("onAddUser", getProjectUsersFn);
      document.removeEventListener("onUserBlock", getBlockedUsers);
    };
  }, []);

  const notifyClient = (e) => {
    if (e?.body) {
      let wsBody = JSON.parse(e?.body);
      if (wsBody["message"] === "project updated") {
        getUserProjects("ws");
        getRequestAccData();
        !(role?.toLowerCase() === "super_admin") && getApprovalRequests();
        !(role?.toLowerCase() === "user") && getProjectPendingRequests("ws");
        !(role?.toLowerCase() === "user") &&
          getProjectRemovalPendingRequests("ws");
      } else if (wsBody?.message === "project approval") {
        !(role?.toLowerCase() === "user") && getProjectPendingRequests("ws");
        getUserProjects("ws");
      } else if (wsBody?.message === "removal action") {
        !(role?.toLowerCase() === "user") &&
          getProjectRemovalPendingRequests("ws");
        getUserProjects("ws");
      } else if (wsBody?.message === "role updated") {
        getUserProjects("ws");
        !(role?.toLowerCase() === "super_admin") && getApprovalRequests();
      }
    }
  };

  useEffect(() => {
    setShowProjectNameMessage(false);
    const timeout = setTimeout(async () => {
      if (
        projectName.length > 0 &&
        /^[A-Za-z0-9 _.-]+$/.test(projectName) &&
        !editProjectDialog
      ) {
        setIsProjectNameValid(true);
        await httpService
          .validateProjectName(projectName.trim())
          .then((data) => {
            if (data["isPresent"]) {
              setProjectNameAlreadyExists(true);
            } else {
              setProjectNameAlreadyExists(false);
            }
          });
      } else {
        setIsProjectNameValid(false);
      }
      if (projectName.length > 0) {
        // to prevent state change on initial render
        setShowProjectNameMessage(true);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [projectName]);

  const getProjectUsersFn = (e) => {
    httpService.getProjectUsers(e.detail.pid).then((data) => {
      if (data?.operation?.toLowerCase() === "success") {
        data?.data
          ? setProjectAccessUsers(data.data)
          : setProjectAccessUsers({});
      }
    });
  };

  useEffect(() => {
    if (Object.keys(pendingRequestFilters).length === 0) {
      const requestFilters = pendingRequestData?.["headers"]
        ? createFilters(pendingRequestData?.["headers"])
        : {};

      setPendingRequestFilters(requestFilters);
    }
  }, [pendingRequestData]);

  useEffect(() => {
    if (Object.keys(userFilters).length === 0) {
      const filterUserData =
        projectAccessUsers && projectAccessUsers?.["headers"]
          ? createFilters(projectAccessUsers?.["headers"])
          : {};
      setUserFilters(filterUserData);
    }
  }, [projectAccessUsers]);

  useEffect(() => {
    if (Object.keys(blockedFilters).length === 0) {
      const blockedFilterData = blockedUsersData?.["headers"]
        ? createFilters(blockedUsersData?.["headers"])
        : {};
      setBlockedFilters(blockedFilterData);
    }
  }, [blockedUsersData]);

  useEffect(() => {
    if (Object.keys(adminFilters)?.length === 0) {
      const filterData = adminProjects?.["headers"]
        ? createFilters(adminProjects?.["headers"])
        : {};
      setAdminFilters(filterData);
    }
  }, [adminProjects]);

  const userBlock_action = (val, data) => {
    return (
      <div>
        <FontAwesomeIcon
          title="Unblock User"
          icon={faUnlock}
          onClick={(e) => {
            unblockUser(data?.Username?.value);
          }}
          className="actionIcon pass-color mx-2"
        />
      </div>
    );
  };

  const unblockUser = (val) => {
    const user = [];
    user.push(val);

    let payload = {
      username: user,
    };

    httpService
      .unblockUsers(payload)
      .then((data) => {
        getToastMessage(data);
        getBlockedUsers();
        const user_unblock = new CustomEvent("onUserUnblock", {
          cancelable: true,
        });
        document.dispatchEvent(user_unblock);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const blockedUsers = () => {
    return (
      <div className="mt-2 mx-1">
        {blockedUsersData && Object.keys(blockedUsersData).length > 0 ? (
          <>
            <DataTable
              reorderableColumns
              onFilter={(e) => {
                setBlockedFilters(e.filters);
              }} // showGridlines
              alwaysShowPaginator={false}
              resizableColumns
              stripedRows
              columnResizeMode="expand"
              value={blockedUsersData ? blockedUsersData?.data : null}
              paginator
              rows={25}
              dataKey="id"
              filters={blockedFilters}
              filterDisplay="menu"
              responsiveLayout="scroll"
              emptyMessage={<NoData />}
              currentPageReportTemplate="Total {totalRecords} User(s) Found"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
              removableSort
              className="test-stepTable"
            >
              {createColumns(
                blockedUsersData?.headers,
                true,
                true,
                blockedUsersData["data"],
                true,
                null,
                {
                  customAction: userBlock_action,
                }
              )}
            </DataTable>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const getTeamData = (companyName, action) => {
    if (role.toString().toUpperCase() === "SUPER_ADMIN") {
      httpService
        .companyUsersSuperAdmin(companyName)
        .then((data) => {
          data?.data?.data
            ? setSameCompanyUsers(data?.data?.data)
            : setSameCompanyUsers([]);
        })
        .catch((err) => {
          handleToastErrorCatch(err);
        });
    } else {
      if (action === "createProject") {
        setIsCreateProjectButtonLoading(true);
      }
      httpService
        .getSameCompanyUsersWithoutPid(companyName)
        .then((data) => {
          data?.data?.data
            ? setSameCompanyUsers(data?.data?.data)
            : setSameCompanyUsers([]);
          setIsCreateProjectButtonLoading(false);
          if (action === "createProject") {
            setCreateProjectDialog(true);
          }
        })
        .catch((err) => {
          handleToastErrorCatch(err, () =>
            setIsCreateProjectButtonLoading(false)
          );
        });
    }
  };
  const skeletonRows = Array.from({ length: 3 });

  const skeletonBodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  const colToggleHeaders = (headers) => {
    headers.map((header) => {
      if (
        header === "Dev Manager" ||
        header === "Dev POC" ||
        header === "QA Lead" ||
        header === "QA Team"
      ) {
        const pushedToggle = !toggleHeaders.current.includes(header)
          ? toggleHeaders.current.push(header)
          : null;
      } else {
        const pushedExisting = !existingHeaders.current.includes(header)
          ? existingHeaders.current.push(header)
          : null;
      }
    });
  };
  const clearNewProjectValues = () => {
    setProjectName("");
    setEnv([]);
    setProjectDescription("");
    setSelectedDevs([]);
    setProjectFlow([
      "New",
      "Under Review",
      "Approved",
      "Decommissioned",
      "In Prod",
    ]);
    setSelectedMembers([]);
    setSelectAllMembers(false);
    setSelectAllDev(false);
    setSelectedPOC([]);
    setSelectAllPOC(false);
    setQaLead([]);
    setSelectAllLead(false);
    setQaTeam([]);
    setSelectAllTeam(false);
  };
  const getUserProjects = (action) => {
    httpService
      .getAdminProjects()
      .then((data) => {
        dispatch({
          type: ADMIN_AVAILABLE_PROJECTS,
          payload: data["data"],
        });
        setAdminProjects(data?.data);
        colToggleHeaders(data?.data?.headers);
        getErrorMessage(data);
        if (action !== "ws") {
          clearNewProjectValues();
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const getBlockedUsers = () => {
    httpService
      .blockedUsers()
      .then((data) => {
        if (data?.operation && data?.operation.toLowerCase() === "failure") {
          getErrorMessage(data);
        }
        setBlockedUsersData(data?.data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const createNewProject = async (e, type) => {
    e.preventDefault();
    if (
      projectName?.length &&
      selectedProjectTypes.length &&
      projectDescription?.length &&
      !(
        selectedProjectTypes.length === 1 &&
        selectedProjectTypes?.[0].toLowerCase() === "application" &&
        env?.length === 0
      )
    ) {
      if (/[^-_\sa-zA-Z0-9.]/.test(projectName)) {
        statementError("No special characters allowed in Project Name!");
        return;
      }

      let invalidEnv = false;

      for (let envValue of env) {
        invalidEnv = /[^-_\sa-zA-Z.]/.test(envValue);

        if (!invalidEnv) {
          continue;
        }
        statementError(
          "No special characters, numeric values or spaces allowed in Environment!"
        );
        break;
      }

      if (invalidEnv) {
        return;
      }

      let payload = {
        projectName: projectName.trim(),
        description: projectDescription,
        managers: selectedDevs,
        qaLead: qaLead,
        devTeam: selectedPOC,
        qaTeam: qaTeam,
        env: env,
        taskFlow: projectFlow,
        projectType: selectedProjectTypes,
        viewers: selectedMembers
      };

      switch (type) {
        case "create":
          setIsCreateProjectButtonLoading(true);
          await httpService
            .createNewProject(payload)
            .then((data) => {
              getToastMessage(data);
              setProjectName("");
              setEnv([]);
              setProjectDescription("");
              setSelectedDevs([]);
              setSelectAllDev(false);
              setSelectedPOC([]);
              setSelectAllPOC(false);
              setQaLead([]);
              setSelectAllLead(false);
              setQaTeam([]);
              setSelectAllTeam(false);
              setProjectNameAlreadyExists(false);
              setIsProjectNameValid(false);
              setCreateProjectDialog(false);
              if (userCompanyName?.toLowerCase() === "individual") {
                getFreeUserQuota();
              }
            })
            .catch((err) => {
              handleToastErrorCatch(err);
            });
          setIsCreateProjectButtonLoading(false);

          break;

        case "edit":
          let editPayload = { ...payload, pid: p_id };
          setIsCreateProjectButtonLoading(true);
          await httpService
            .editAdminProject(editPayload)
            .then((data) => {
              getToastMessage(data);
              setProjectName("");
              setEnv([]);
              setProjectDescription("");
              setSelectedDevs([]);
              setSelectAllDev(false);
              setSelectedPOC([]);
              setSelectAllPOC(false);
              setQaLead([]);
              setSelectAllLead(false);
              setQaTeam([]);
              setSelectAllTeam(false);
              setProjectNameAlreadyExists(false);
              setIsProjectNameValid(false);
              setEditProjectDialog(false);
            })
            .catch((err) => {
              handleToastErrorCatch(err);
            });
          setIsCreateProjectButtonLoading(false);
          break;

        default:
          return null;
      }
    } else {
      statementError("Please fill out all Mandatory Fields!");
    }
  };

  const deletePid = (delete_pid) => {
    setDeleteLoading(true);
    httpService
      .deleteProject(delete_pid)
      .then((data) => {
        getToastMessage(data);
        setDeleteLoading(false);
        setDeleteDialog(false);
        if (userCompanyName?.toLowerCase() === "individual") {
          getFreeUserQuota();
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };
  const removalRequestAction = (type) => {
    let payload = disableBulkButtons.map((item) => ({
      pid: item?.Actions?.value,
      username: item?.Name?.value,
    }));
    httpService
      .postProjectRemovalRequest(payload, type)
      .then((data) => {
        getInfoMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      })
      .finally(() => {
        setIsRejectAllButtonLoading(false);
        setIsApproveAllButtonLoading(false);
      });
  };
  const userOptionTemplate = (option) => {
    return (
      <>
        <div>
          <span>{`${option.Name} ( ${option.Username} )`}</span>
        </div>
      </>
    );
  };
  const ProjectNameInfoTooltip = () => {
    return (
      <span>
        <Tooltip
          target=".custom-target-icon-project-name"
          className="signupTooltip"
        >
          <>
            <p>Valid Project Name Suggestions</p>
            <p className="mt-1">
              Mandatory
              <ul className="mt-0" style={{ lineHeight: "1.5" }}>
                <li>Alphabetical characters </li>
              </ul>
            </p>
            <p className="mt-1">
              Optional
              <ul className="mt-0" style={{ lineHeight: "1.5" }}>
                <li>Space</li>
                <li>Any Numerical value</li>
                <li>Special characters (dot,hyphen,underscore)</li>
              </ul>
            </p>
          </>
        </Tooltip>
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="infoicon fa-sm ms-2 custom-target-icon-project-name"
          data-pr-position="right"
          data-pr-at="right+2 top"
        />
      </span>
    );
  };

  const createProjectDetails = (type) => {
    return (
      <>
        <div className="new-project">
          <form onSubmit={(e) => createNewProject(e, type)}>
            <div className="mt-2 p-2">
              {(type === "create" && userCompanyName?.toLowerCase() === "individual") &&
                <Message
                  severity="info"
                  text={`Project onboarding limit: ${freeUserStats?.projects} Project(s) left`}
                  sticky
                />
              }
              <div className="row p-1 d-flex">
                <div className="col-sm-6 col-12 justify-content-center">
                  <div className="ms-1 mb-1 mt-1 pDetails-heading">
                    Project Name<sup> *</sup>
                  </div>

                  <div className="w-100">
                    <div className="p-input-icon-right w-100">
                      <i className="pi">
                        <ProjectNameInfoTooltip />
                      </i>
                      <InputText
                        type={"text"}
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        className="w-100"
                        placeholder={"Project Name"}
                        disabled={type === "edit"}
                      />
                    </div>

                    {showProjectNameMessage &&
                      projectName.length > 0 &&
                      type === "create" && (
                        <>
                          {isProjectNameValid ? (
                            <>
                              {projectNameAlreadyExists ? (
                                <div className="pCheck-not mt-1">
                                  <span className="me-1">
                                    Project Name Already Exists
                                  </span>
                                  <FontAwesomeIcon icon={faCircleXmark} />
                                </div>
                              ) : (
                                <div className="pCheck mt-1">
                                  <span className="me-1">
                                    Project Name Available
                                  </span>
                                  <FontAwesomeIcon icon={faCircleCheck} />
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="pCheck-not mt-1">
                              <span className="me-1">
                                Please enter a valid Project Name
                              </span>
                              <FontAwesomeIcon icon={faCircleXmark} />
                            </div>
                          )}
                        </>
                      )}
                  </div>
                </div>
                <div className="col-sm-6 col-12 justify-content-center">
                  <div className="ms-1 mb-1 mt-1 pDetails-heading d-flex align-items-center">
                    Project Type<sup>*</sup>

                    {!(userCompanyName?.toLowerCase() === "individual") && (
                      <div>
                        <FontAwesomeIcon
                          id="projectTypeTooltip"
                          icon={faCircleInfo}
                          className="infoicon ms-1"
                        ></FontAwesomeIcon>
                        <Tooltip
                          target="#projectTypeTooltip"
                          content={"Select 'Team' for AgileCrafter, 'Application' for Jewel.\n\nSelecting both grants the project access to both."}
                          position="top"
                        />
                      </div>)}
                  </div>
                  <div className="w-100">
                    <MultiSelect
                      ref={multiSelectRef}
                      disabled={userCompanyName?.toLowerCase() === "individual"}
                      name="selectedProjectType"
                      className="multiselect-custom w-100"
                      value={selectedProjectTypes}
                      options={[
                        { label: 'Team', value: 'TEAM' },
                        { label: 'Application', value: 'APPLICATION' }
                      ]}
                      onChange={(e) => {
                        setSelectedProjectTypes(e.value);
                        multiSelectRef.current?.hide();
                      }}
                      placeholder="Select Type(s)"
                    />
                  </div>
                </div>
              </div>
              <div className="row p-1 d-flex">
                <div className="ms-1 mb-1 mt-1 pDetails-heading">
                  Task Flow<sup> *</sup>
                </div>
                <div className="w-100">
                  <Chips
                    value={projectFlow}
                    allowDuplicate={false}
                    onChange={(e) => setProjectFlow(e.value)}
                    className="w-100 p-fluid chipsenv"
                  />
                </div>
                <div className="ms-1 mb-1 mt-1 pDetails-smheading">
                  Note: Press Enter to add a new flow value !
                </div>
              </div>
              <div className="p-1 mt-3">
                <div className="ms-1 mb-1 pDetails-heading">
                  Project Description<sup> *</sup>
                </div>
                <InputTextarea
                  value={projectDescription}
                  id="desc"
                  className="w-100"
                  onChange={(e) => {
                    setProjectDescription(e.target.value);
                  }}
                  rows={5}
                  autoResize
                />
              </div>
              <div className="row p-1 mt-3">
                {!(userCompanyName?.toLowerCase() === "individual") &&
                  <div className="col-sm-6 col-12">
                    <div className="ms-1 mb-1 mt-1 pDetails-heading">
                      Team Member(s)
                    </div>
                    {sameCompanyUsers.length > 0 ? (
                      <MultiSelect
                        value={selectedMembers}
                        options={sameCompanyUsers}
                        onChange={(e) => {
                          setSelectedMembers(e.value);
                          setSelectAllMembers(
                            e.value.length === sameCompanyUsers.length
                          );
                        }}
                        selectAll={selectAllMembers}
                        onSelectAll={(e) => {
                          setSelectedMembers(
                            e.checked
                              ? []
                              : sameCompanyUsers.map((user) => user.Username)
                          );
                          setSelectAllMembers(!e.checked);
                        }}
                        virtualScrollerOptions={{ itemSize: 20 }}
                        optionLabel="Name"
                        optionValue="Username"
                        placeholder="Select"
                        filter
                        maxSelectedLabels={1}
                        className="multiselect-custom w-100"
                        itemTemplate={userOptionTemplate}
                      />
                    ) : (
                      <Dropdown
                        className="dialog-dropdown w-100"
                        options={[]}
                        emptyMessage="No Active User(s) in your Company"
                        placeholder="Select User(s) to Add"
                      />
                    )}
                  </div>}
                {(selectedProjectTypes.length === 2 || (selectedProjectTypes.length === 1 && selectedProjectTypes?.[0].toLowerCase() === "application"))
                  && (
                    <div className="col-sm-6 col-12 justify-content-center">
                      <div className="ms-1 mb-1 mt-1 pDetails-heading">
                        Environment(s)<sup> *</sup>
                      </div>
                      <div className="w-100">
                        <Chips
                          value={env}
                          allowDuplicate={false}
                          onChange={(e) => setEnv(e.value)}
                          className="w-100 p-fluid chipsenv"
                        />
                      </div>
                      <div className="ms-1 mt-1 pDetails-smheading">
                        Note: Press Enter to add a new env value !
                      </div>
                    </div>
                  )}
              </div>
              {((selectedProjectTypes.length === 2 || (selectedProjectTypes.length === 1 && selectedProjectTypes?.[0].toLowerCase() === "application")) && !(userCompanyName?.toLowerCase() === "individual")) && (
                <>
                  <div className="row p-1 mt-1">
                    <div className="col-sm-6 col-12">
                      <div className="ms-1 mb-1 mt-1 pDetails-heading">
                        Dev Manager(s)
                      </div>
                      {sameCompanyUsers.length > 0 ? (
                        <MultiSelect
                          value={selectedDevs}
                          options={sameCompanyUsers}
                          onChange={(e) => {
                            setSelectedDevs(e.value);
                            setSelectAllDev(
                              e.value.length === sameCompanyUsers.length
                            );
                          }}
                          selectAll={selectAllDev}
                          onSelectAll={(e) => {
                            setSelectedDevs(
                              e.checked
                                ? []
                                : sameCompanyUsers.map((user) => user.Username)
                            );
                            setSelectAllDev(!e.checked);
                          }}
                          virtualScrollerOptions={{ itemSize: 20 }}
                          optionLabel="Name"
                          optionValue="Username"
                          placeholder="Select"
                          filter
                          maxSelectedLabels={1}
                          className="multiselect-custom w-100"
                          itemTemplate={userOptionTemplate}
                        />
                      ) : (
                        <Dropdown
                          className="dialog-dropdown w-100"
                          options={[]}
                          emptyMessage="No Active User(s) in your Company"
                          placeholder="Select User(s) to Add"
                        />
                      )}
                    </div>

                    <div className="col-sm-6 col-12">
                      <div className="ms-1 mb-1 mt-1 pDetails-heading">Dev POC</div>
                      {sameCompanyUsers.length > 0 ? (
                        <MultiSelect
                          emptyMessage={"No Active User(s) in your Company"}
                          value={selectedPOC}
                          options={sameCompanyUsers}
                          onChange={(e) => {
                            setSelectedPOC(e.value);
                            setSelectAllPOC(
                              e.value.length === sameCompanyUsers.length
                            );
                          }}
                          selectAll={selectAllPOC}
                          onSelectAll={(e) => {
                            setSelectedPOC(
                              e.checked
                                ? []
                                : sameCompanyUsers.map((user) => user.Username)
                            );
                            setSelectAllPOC(!e.checked);
                          }}
                          virtualScrollerOptions={{ itemSize: 20 }}
                          optionLabel="Name"
                          optionValue="Username"
                          placeholder="Select"
                          filter
                          maxSelectedLabels={1}
                          className="w-100"
                          itemTemplate={userOptionTemplate}
                        />
                      ) : (
                        <Dropdown
                          className="dialog-dropdown w-100"
                          options={[]}
                          emptyMessage="No Active User(s) in your Company"
                          placeholder="Select User(s) to Add"
                        />
                      )}
                    </div>
                  </div>

                  <div className="row p-1 mt-1">
                    <div className="col-sm-6 col-12">
                      <div className="ms-1 mb-1 mt-1 pDetails-heading">
                        QA Lead(s)
                      </div>
                      {sameCompanyUsers.length > 0 ? (
                        <MultiSelect
                          emptyMessage={"No Active User(s) in your Company"}
                          value={qaLead}
                          options={sameCompanyUsers}
                          onChange={(e) => {
                            setQaLead(e.value);
                            setSelectAllLead(
                              e.value.length === sameCompanyUsers.length
                            );
                          }}
                          selectAll={selectAllLead}
                          onSelectAll={(e) => {
                            setQaLead(
                              e.checked
                                ? []
                                : sameCompanyUsers.map((user) => user.Username)
                            );
                            setSelectAllLead(!e.checked);
                          }}
                          virtualScrollerOptions={{ itemSize: 20 }}
                          optionLabel="Name"
                          optionValue="Username"
                          placeholder="Select"
                          filter
                          maxSelectedLabels={1}
                          className="w-100"
                          itemTemplate={userOptionTemplate}
                        />
                      ) : (
                        <Dropdown
                          className="dialog-dropdown w-100"
                          options={[]}
                          emptyMessage="No Active User(s) in your Company"
                          placeholder="Select User(s) to Add"
                        />
                      )}
                    </div>

                    <div className="col-sm-6 col-12">
                      <div className="ms-1 mb-1 mt-1 pDetails-heading">QA Team</div>
                      {sameCompanyUsers.length > 0 ? (
                        <MultiSelect
                          emptyMessage={"No Active User(s) in your Company"}
                          value={qaTeam}
                          options={sameCompanyUsers}
                          onChange={(e) => {
                            setQaTeam(e.value);
                            setSelectAllTeam(
                              e.value.length === sameCompanyUsers.length
                            );
                          }}
                          selectAll={selectAllTeam}
                          onSelectAll={(e) => {
                            setQaTeam(
                              e.checked
                                ? []
                                : sameCompanyUsers.map((user) => user.Username)
                            );
                            setSelectAllTeam(!e.checked);
                          }}
                          virtualScrollerOptions={{ itemSize: 20 }}
                          optionLabel="Name"
                          optionValue="Username"
                          placeholder="Select"
                          filter
                          maxSelectedLabels={1}
                          className="w-100"
                          itemTemplate={userOptionTemplate}
                        />
                      ) : (
                        <Dropdown
                          className="dialog-dropdown w-100"
                          options={[]}
                          emptyMessage="No Active User(s) in your Company"
                          placeholder="Select User(s) to Add"
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="text-center mt-5">
                {type === "create" ? (
                  <Button
                    label="Create"
                    type="submit"
                    className="btn btn-success themeBtn px-4 py-2"
                    disabled={!isProjectNameValid}
                    loadingIcon={"pi pi-spin pi-spinner me-1"}
                    loading={isCreateProjectButtonLoading}
                    iconPos="right"
                  />
                ) : (
                  <Button
                    label="Save"
                    type="submit"
                    className="btn btn-success themeBtn px-4 py-2"
                    loadingIcon={"pi pi-spin pi-spinner"}
                    loading={isCreateProjectButtonLoading}
                    iconPos="right"
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </>
    );
  };

  const getApprovalRequests = () => {
    httpService
      .getProjectRoleRequests()
      .then((data) => {
        data?.data
          ? setAccessApprovalData(data?.data)
          : setAccessApprovalData({});
        setCounter(data?.data?.totalElements);
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };
  const editProject = (e, val) => {
    getTeamData(companyName);
    setP_id(val);
    httpService
      .editProjectDetails(val)
      .then((data) => {
        if (data?.operation?.toLowerCase() === "success") {
          setProjectNameAlreadyExists(false);
          setIsProjectNameValid(false);
          setEditProjectDialog(true);
          setEditProjectDialog(true);
          data?.data?.taskFlow?.length > 0 && setProjectFlow(data?.data?.taskFlow);
          setSelectedProjectTypes(data?.data?.projectType)
          setSelectedMembers(data?.data?.viewers);
          setProjectName(data?.data?.projectName);
          setEnv(data?.data?.env);
          setProjectDescription(data?.data?.description);
          setSelectedDevs(data?.data?.managers);
          setSelectedPOC(data?.data?.devTeam);
          setQaLead(data?.data?.qaLead);
          setQaTeam(data?.data?.qaTeam);
        }
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const deleteProject = (e, val, pName, access) => {
    if (access?.value === "ADMIN") {
      toDeletePName.current = pName?.value;
      toDeletePId.current = val;
      setDeleteDialog(true);
    } else {
      statementError("You don't have Admin access to this project!");
    }
  };

  const editProjectAccess = (e, val, project_name, companyName) => {
    setP_id(val);
    setProjectAccessUsers(null)
    httpService
      .getProjectUsers(val)
      .then((data) => {
        setCompanyName(companyName);
        getTeamData(companyName);
        getErrorMessage(data);
        if (data?.operation?.toLowerCase() === "success") {
          data?.data
            ? setProjectAccessUsers(data?.data)
            : setProjectAccessUsers({});
          setEditAccessDialog(true);
          editAccessDialogTitle.current = project_name;
        } else {
          setEditAccessDialog(false);
          editAccessDialogTitle.current = "";
        }
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };

  const requestAccess = async () => {
    if (selectedProjectForAccess?.length > 0 && selectedAccess?.length > 0) {
      let payload = [];
      selectedProjectForAccess.map((project) => {
        let Prequest = {
          pid: project.toString(),
          role: selectedAccess.toLowerCase(),
        };
        payload.push(Prequest);
      });
      setRequestAcessDialogButtonLoading(true);
      await httpService
        .getAccess(payload)
        .then((response) => {
          if (
            response?.operation?.toLowerCase() === "success" &&
            response?.data &&
            typeof response?.data === "object"
          ) {
            Object.values(response?.data).map((value) => {
              toast.error(`${value}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: "mt-5",
              });
            });
          }
          getToastMessage(response);
          setPendingRequestData(null);
          httpService
            .getPendingRolesRequest()
            .then((data) => {
              getErrorMessage(data);
              setPendingRequestData(data?.["data"]);
            })
            .catch((err) => {
              handleToastErrorCatch(err, () =>
                setRequestAcessButtonLoading(false)
              );
            });
          // setRequestAccessDialog(false);
        })
        .catch((err) => {
          handleToastErrorCatch(err, () => setRequestAccessDialog(false));
        });
      setRequestAcessDialogButtonLoading(false);
    } else {
      if (
        selectedAccess.length === 0 &&
        selectedProjectForAccess.length === 0
      ) {
        statementError("All Fields are required!");
      } else {
        toast.error(
          selectedAccess?.length === 0
            ? "No Access Role Selected for the Project!"
            : "No Project Selected for Access Request!",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "mt-5",
          }
        );
      }
    }
  };

  const onColumnToggle = (e) => {
    setSelectedColumns(e.value);
  };
  const header = (
    <div style={{ textAlign: "left", minHeight: "2vh" }}>
      <MultiSelect
        placeholder="More Detail(s)"
        value={selectedColumns}
        options={toggleHeaders.current}
        onChange={(e) => onColumnToggle(e)}
        maxSelectedLabels={1}
        style={{ width: "15vw" }}
      />
    </div>
  );

  const requestAccessDialogContent = () => {
    return (
      <>
        <div
          className="tab-pane"
          id={"pills-access"}
          role="tabpanel"
          aria-labelledby={"pills-access-tab"}
        >
          <div className="d-inline-block wrapperPillsAdmin p-0">
            <ul className="nav nav-pills" id={"pills-tab"} role="tablist">
              <li className="nav-item mr-1" role="presentation">
                <div
                  className={`fontSizeGeneral nav-link requestPillAdmin ${activeTab === "newRequest" ? "active" : ""
                    }`}
                  id="pills-new-request-tab"
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "newRequest"}
                  onClick={() => setActiveTab("newRequest")}
                >
                  New Request
                </div>
              </li>
              <li className="nav-item mr-1" role="presentation">
                <div
                  className={`fontSizeGeneral nav-link requestPillAdmin ${activeTab === "pendingRequest" ? "active" : ""
                    }`}
                  id="pills-pending-request-tab"
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "pendingRequest"}
                  onClick={() => setActiveTab("pendingRequest")}
                >
                  Pending Requests
                </div>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade ${activeTab === "newRequest" ? "show active" : ""
                } mt-3 mb-2`}
              id="pills-new-request"
              role="tabpanel"
            >
              <div className="d-flex flex-column mt-4 justify-content-center">
                {" "}
                <div className="">
                  {allProjectsName.length > 0 ? (
                    <MultiSelect
                      value={selectedProjectForAccess}
                      options={allProjectsName}
                      style={{ minWidth: "24vw", maxWidth: "24vw" }}
                      onChange={(e) => setSelectedProjectForAccess(e.value)}
                      emptyMessage={"No Active Project(s) in your Company"}
                      optionLabel="Project Name"
                      display="chip"
                      optionValue="P ID"
                      placeholder="Select Project(s)"
                      filter
                      showClear
                      className="multiselect-custom w-100"
                    />
                  ) : (
                    <Dropdown
                      className="dialog-dropdown w-100"
                      style={{ minWidth: "24vw", maxWidth: "24vw" }}
                      options={[]}
                      emptyMessage="No Active Project(s) in your Company"
                      placeholder="Select Project(s)"
                    />
                  )}
                  <Dropdown
                    className="dialog-dropdown ms-3"
                    style={{ minWidth: "15vw", maxWidth: "15vw" }}
                    options={["Admin", "Dev", "QA", "Viewer"]}
                    value={selectedAccess}
                    onChange={(e) => setSelectedAccess(e.value)}
                    placeholder="Select Access Role(s)"
                    maxSelectedLabels={1}
                  />
                </div>
                <div className="mt-4">
                  <Button
                    label="Request Access"
                    type="submit"
                    className="btn btn-success py-2 themeBtn"
                    onClick={() => {
                      requestAccess();
                      setSelectedProjectForAccess([]);
                      setSelectedAccess("");
                    }}
                    loadingIcon={"pi pi-spin pi-spinner me-2"}
                    loading={requestAccessDialogButtonLoading}
                    iconPos="right"
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${activeTab === "pendingRequest" ? "show active" : ""
                } my-2`}
              id="pills-pending-request"
              role="tabpanel"
            >
              <div className="mt-3 mx-2">
                {pendingRequestData ? (
                  <DataTable
                    reorderableColumns
                    header={"Pending Request(s)"}
                    onFilter={(e) => {
                      setPendingRequestFilters(e.filters);
                    }}
                    resizableColumns
                    stripedRows
                    columnResizeMode="expand"
                    value={pendingRequestData ? pendingRequestData?.data : null}
                    paginator
                    rows={25}
                    dataKey="id"
                    filters={pendingRequestFilters}
                    rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    scrollHeight={"35vh"}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    emptyMessage={<NoData />}
                    currentPageReportTemplate="Total {totalRecords} Request(s) Found"
                    removableSort
                    className="test-stepTable"
                  >
                    {createColumns(
                      pendingRequestData?.headers,
                      true,
                      true,
                      pendingRequestData?.data,
                      true
                    )}
                  </DataTable>
                ) : (
                  <SkeletonUtility
                    header={true}
                    headerValue={"Pending Request(s)"}
                    paginator={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getPendingRequests = () => {
    setRequestAcessButtonLoading(true);
    httpService
      .getPendingRolesRequest()
      .then((data) => {
        getErrorMessage(data);
        setPendingRequestData(data?.data);
        setRequestAcessButtonLoading(false);
        setRequestAccessDialog(true);
        setSelectedProjectForAccess([]);
        setSelectedAccess("");
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setRequestAcessButtonLoading(false));
      });
  };
  const getProjectPendingRequests = (action) => {
    action !== "ws" && setProjectAccessApprovalLoading(true);
    httpService
      .getPendingProjectRequests()
      .then((data) => {
        setBulkActionTrigger((prev) => !prev);
        setProjectAccessApprovalData(data?.data);
        setProjectCounter(data?.data?.totalElements);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setRequestAcessButtonLoading(false));
      })
      .finally(() => {
        setProjectAccessApprovalLoading(false);
        setSelectedUser([]);
        setDisableBulkButtons([]);
      });
  };
  const getProjectRemovalPendingRequests = (action) => {
    action !== "ws" && setProjectRemovalApprovalLoading(true);
    httpService
      .getPendingProjectRemovalRequests()
      .then((data) => {
        setBulkActionTrigger((prev) => !prev);
        setProjectRemovalApprovalData(data?.data);
        setProjectRemovalCounter(data?.data?.totalElements);
      })
      .catch((err) => {
        handleToastErrorCatch(err, () => setRequestAcessButtonLoading(false));
      })
      .finally(() => {
        setProjectRemovalApprovalLoading(false);
        setSelectedUser([]);
        setDisableBulkButtons([]);
      });
  };
  const getRequestAccData = () => {
    httpService
      .getRequestAccProjects()
      .then((data) => {
        data?.data?.data
          ? setAllProjectsName(data?.data?.data)
          : setAllProjectsName([]);
        getErrorMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      });
  };
  const requestAction = (type) => {
    let pids = disableBulkButtons
      .map((entry) => entry?.Actions?.value)
      .join(",");
    httpService
      .postPendingProjectRequest({ pids: pids, action: type })
      .then((data) => {
        getInfoMessage(data);
      })
      .catch((err) => {
        handleToastErrorCatch(err);
      })
      .finally(() => {
        setIsRejectAllButtonLoading(false);
        setIsApproveAllButtonLoading(false);
      });
  };
  const footer = (
    <div>
      {disableBulkButtons?.length > 0 && (
        <Button
          label="Reject All"
          className="mx-1 py-2 mt-2"
          onClick={() => {
            projectRemovalApprovalDialog
              ? removalRequestAction("decline")
              : requestAction("reject");
            setIsRejectAllButtonLoading(true);
          }}
          disabled={isApproveAllButtonLoading}
          loadingIcon={"pi pi-spin pi-spinner me-2"}
          loading={isRejectAllButtonLoading}
          iconPos="right"
          style={{
            backgroundColor: "white",
            color: "red",
            border: "1px solid red",
          }}
        />
      )}
      {disableBulkButtons?.length > 0 && (
        <Button
          label="Approve All"
          className="btn-success themeBtn mx-1 py-2 "
          onClick={() => {
            projectRemovalApprovalDialog
              ? removalRequestAction("accept")
              : requestAction("approve");
            setIsApproveAllButtonLoading(true);
          }}
          disabled={isRejectAllButtonLoading}
          loadingIcon={"pi pi-spin pi-spinner me-2"}
          loading={isApproveAllButtonLoading}
          iconPos="right"
        />
      )}
    </div>
  );
  return (
    <>
      <div className="container-fluid adminSection pb-5">
        <div className="headerAdmin d-flex flex-wrap align-items-center pt-2 mx-2">
          <div className="d-flex align-items-center ms-2 mb-3 pt-md-2 pb-1">
            <span className="userIcon">
              {" "}
              <FontAwesomeIcon icon={faUser} />
            </span>
            <span className="ms-3 pt-1 pb-1 AdminHeading"> Admin</span>
          </div>
          <div className="adminBtns ms-sm-auto pt-md-2 mb-3">
            {role.toLowerCase() === "user" && !(userCompanyName?.toLowerCase() === "individual") ? (
              <Button
                label="Request Access"
                type="submit"
                className="btn-success btn btn-sm themeBtn mx-2 p-2 mt-1"
                onClick={() => getPendingRequests()}
                loadingIcon={"pi pi-spin pi-spinner me-2"}
                loading={requestAccessButtonLoading}
                iconPos="right"
              />
            ) : null}
            {(!(role.toLowerCase() === "user") && !(userCompanyName?.toLowerCase() === "individual")) && (
              <button
                className="btn-success btn themeBtn mx-2 mt-1 p-2 p-overlay-badge"
                onClick={() => {
                  getProjectPendingRequests();
                  setProjectAccessApprovalDialog(true);
                }}
              >
                {projectCounter ? <Badge value={projectCounter}></Badge> : null}
                Project Approval
              </button>
            )}
            {(role.toString().toUpperCase() !== "SUPER_ADMIN" && !(userCompanyName?.toLowerCase() === "individual")) && (
              <button
                className="btn-success btn themeBtn mx-2 mt-1 p-2 p-overlay-badge"
                onClick={() => {
                  getProjectRemovalPendingRequests();
                  setProjectRemovalApprovalDialog(true);
                }}
              >
                {projectRemovalCounter ? (
                  <Badge value={projectRemovalCounter}></Badge>
                ) : null}
                Project Removal Request
              </button>
            )}
            {role.toString().toUpperCase() !== "SUPER_ADMIN" && (
              <>
                {!(userCompanyName?.toLowerCase() === "individual") &&
                  <button
                    className="btn-success btn themeBtn mx-2 mt-1 p-2 p-overlay-badge"
                    onClick={() => {
                      setAccessApprovalDialog(true);
                    }}
                  >
                    {counter ? <Badge value={counter}></Badge> : null}
                    Access Approval
                  </button>
                }
                <Button
                  className="btn-success btn themeBtn mx-2 mt-1 p-2"
                  onClick={() => {
                    !(userCompanyName?.toLowerCase() === "individual") ?
                      getTeamData(userCompanyName, "createProject")
                      : setCreateProjectDialog(true)
                  }}
                  disabled={freeUserStats?.projects === 0}
                  label="Create Project"
                  loadingIcon={"pi pi-spin pi-spinner me-2"}
                  loading={isCreateProjectButtonLoading}
                />
              </>
            )}
            {role.toLowerCase() === "admin" ? (
              <button
                className="btn-success btn themeBtn mx-2 mt-1 p-2"
                onClick={() => {
                  setBlockedUsersDialog(true);
                }}
              >
                Blocked Users
              </button>
            ) : null}
          </div>
        </div>
        {/* tabsAdmin */}
        <div>
          <ul
            className="nav nav-pills my-3 mx-2"
            id={"pills-tab"}
            role="tablist"
          >
            <li className="nav-item mr-1" role="presentation">
              <div
                className="nav-link changeRoleLink active"
                id={"pills-myProjects-tab-"}
                data-toggle="pill"
                data-target={"#pills-myProjects"}
                type="button"
                role="tab"
                aria-selected="true"
              >
                Projects
              </div>
            </li>
            {role.toLowerCase() === "admin" ? (
              <li className="nav-item" role="presentation">
                <div
                  className="nav-link addUserLink"
                  id={"pills-myCompany-tab-"}
                  data-toggle="pill"
                  data-target={"#pills-myCompany-"}
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  My Company
                </div>
              </li>
            ) : null}
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id={"pills-myProjects"}
              role="tabpanel"
              aria-labelledby={"pills-myProjects-tab-"}
            >
              <div className="project-details mt-3 mx-2">
                {adminProjects ? (
                  <>
                    {freeUserStats?.projects === 0 &&
                      <Message
                        severity="warn"
                        text={`Project limit reached! You can just create up to 3 projects with a free account.`}
                        sticky
                        className="mb-3 w-100"
                      />}
                    <DataTable
                      header={(userCompanyName?.toLowerCase() === "individual") ? <div>Project(s)</div> : header}
                      reorderableColumns
                      onFilter={(e) => {
                        setAdminFilters(e.filters);
                      }}
                      resizableColumns
                      stripedRows
                      columnResizeMode="expand"
                      value={adminProjects ? adminProjects?.data : null}
                      paginator
                      rows={25}
                      dataKey="id"
                      filters={adminFilters}
                      rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      scrollHeight={"65vh"}
                      filterDisplay="menu"
                      responsiveLayout="scroll"
                      emptyMessage={<NoData />}
                      currentPageReportTemplate="Total {totalRecords} Project(s) Found"
                      removableSort
                      className="test-stepTable"
                    >
                      {createColumns(
                        existingHeaders.current,
                        true,
                        true,
                        adminProjects?.data,
                        true,
                        null,
                        null,
                        {
                          crud_project: true, crud_status: true
                        },
                        {
                          crud_project: CrudProject, crud_status: crudStatus
                        },
                        {
                          crud_project_props: {
                            edit_project: { editProject },
                            delete_project: { deleteProject },
                            edit_roles: { editProjectAccess },
                            userCompanyName: userCompanyName,
                          },
                        }
                      )}
                      {createColumns(
                        selectedColumns,
                        true,
                        true,
                        adminProjects?.data
                      )}
                    </DataTable>
                  </>
                ) : (
                  <DataTable
                    responsiveLayout="scroll"
                    header={
                      <>
                        <div className="headingFont">
                          <Skeleton
                            width="15rem"
                            className="my-3 ms-2"
                          ></Skeleton>
                        </div>
                      </>
                    }
                    value={skeletonRows}
                    className="p-datatable-striped mt-4 test-stepTable"
                  >
                    <Column
                      header={
                        <Skeleton
                          width="5rem"
                          className="mb-2 mx-auto"
                        ></Skeleton>
                      }
                      style={{ width: "5%" }}
                      body={skeletonBodyTemplate}
                    ></Column>
                    <Column
                      header={
                        <Skeleton
                          width="5rem"
                          className="mb-2 mx-auto"
                        ></Skeleton>
                      }
                      style={{ width: "25%" }}
                      body={skeletonBodyTemplate}
                    ></Column>
                    <Column
                      header={
                        <Skeleton
                          width="5rem"
                          className="mb-2 mx-auto"
                        ></Skeleton>
                      }
                      style={{ width: "25%" }}
                      body={skeletonBodyTemplate}
                    ></Column>
                    <Column
                      header={
                        <Skeleton
                          width="5rem"
                          className="mb-2 mx-auto"
                        ></Skeleton>
                      }
                      style={{ width: "33%" }}
                      body={skeletonBodyTemplate}
                    ></Column>

                    <Column
                      header={
                        <Skeleton
                          width="5rem"
                          className="mb-2 mx-auto"
                        ></Skeleton>
                      }
                      style={{ width: "27%" }}
                      body={skeletonBodyTemplate}
                    ></Column>
                  </DataTable>
                )}
              </div>
            </div>
            {role.toLowerCase() === "admin" ? (
              <div
                className="tab-pane fade"
                id={"pills-myCompany-"}
                role="tabpanel"
                aria-labelledby={"pills-myCompany-tab-"}
              >
                <CompanyAdmin />
              </div>
            ) : null}
          </div>
          {/* tabsAdmin */}
        </div>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Request Access"
          visible={requestAccessDialog}
          onHide={() => {
            setRequestAccessDialog(false);
            setSelectedProjectForAccess([]);
            setSelectedAccess("");
          }}
          className="accessApprovalDialog"
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "60vw" }}
        >
          {requestAccessDialogContent()}
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Project Request Access"
          visible={projectAccessApprovalDialog}
          onHide={() => {
            setProjectAccessApprovalDialog(false);
          }}
          footer={footer}
          style={{ width: "80vw", marginLeft: "10rem" }}
        >
          <div className="my-3 p-2">
            {projectAccessApprovalLoading ? (
              <DataTable
                responsiveLayout="scroll"
                header={
                  <>
                    <div className="headingFont">
                      <Skeleton width="15rem" className="my-3 ms-2"></Skeleton>
                    </div>
                  </>
                }
                value={skeletonRows}
                className="p-datatable-striped mt-4 test-stepTable"
              >
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "5%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "25%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "25%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "33%" }}
                  body={skeletonBodyTemplate}
                ></Column>

                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "27%" }}
                  body={skeletonBodyTemplate}
                ></Column>
              </DataTable>
            ) : (
              <DataTable
                header={<>Project Access Approval</>}
                reorderableColumns
                resizableColumns
                stripedRows
                selectionMode="checkbox"
                selection={selectedUser}
                onSelectionChange={(e) => {
                  setSelectedUser(e?.value);
                }}
                columnResizeMode="expand"
                value={projectAccessApprovalData?.data}
                paginator
                rows={4}
                rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                scrollHeight={"65vh"}
                responsiveLayout="scroll"
                emptyMessage={<NoData />}
                removableSort
                className="test-stepTable borderedRows mb-3"
              >
                <Column
                  selectionMode={
                    projectAccessApprovalData?.data?.length > 0
                      ? "multiple"
                      : "none"
                  }
                  headerClassName={`headercolumnsPrime`}
                  className={"columnsPrime"}
                  headerStyle={{ width: "3em" }}
                ></Column>
                {createColumns(
                  projectAccessApprovalData?.headers,
                  true,
                  false,
                  projectAccessApprovalData?.data,
                  true,
                  false,
                  false,
                  {
                    crud_project: true,
                  },
                  {
                    crud_project: ProjectAccessAdmin,
                  },
                  {
                    crud_project_props: {
                      selectedUser: selectedUser,
                      setSelectedUser: setSelectedUser,
                      disableBulkButtons: disableBulkButtons,
                      setProjectAccessApprovalDialog:
                        setProjectAccessApprovalDialog,
                      bulkActionTrigger: bulkActionTrigger,
                    },
                  }
                )}
              </DataTable>
            )}
          </div>
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Project Removal Access"
          visible={projectRemovalApprovalDialog}
          onHide={() => {
            setProjectRemovalApprovalDialog(false);
          }}
          footer={footer}
          style={{ width: "80vw", marginLeft: "10rem" }}
        >
          <div className="my-3 p-2">
            {projectRemovalApprovalLoading ? (
              <DataTable
                responsiveLayout="scroll"
                header={
                  <>
                    <div className="headingFont">
                      <Skeleton width="15rem" className="my-3 ms-2"></Skeleton>
                    </div>
                  </>
                }
                value={skeletonRows}
                className="p-datatable-striped mt-4 test-stepTable"
              >
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "5%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "25%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "25%" }}
                  body={skeletonBodyTemplate}
                ></Column>
                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "33%" }}
                  body={skeletonBodyTemplate}
                ></Column>

                <Column
                  header={
                    <Skeleton width="5rem" className="mb-2 mx-auto"></Skeleton>
                  }
                  style={{ width: "27%" }}
                  body={skeletonBodyTemplate}
                ></Column>
              </DataTable>
            ) : (
              <DataTable
                header={<>Project Removal Approval</>}
                reorderableColumns
                resizableColumns
                stripedRows
                selectionMode="multiple"
                selection={selectedUser}
                onSelectionChange={(e) => {
                  setSelectedUser(e?.value);
                }}
                columnResizeMode="expand"
                value={projectRemovalApprovalData?.data}
                paginator
                rows={4}
                rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                scrollHeight={"65vh"}
                responsiveLayout="scroll"
                emptyMessage={<NoData />}
                removableSort
                className="test-stepTable borderedRows mb-3"
              >
                <Column
                  selectionMode={
                    projectRemovalApprovalData?.data?.length > 0
                      ? "multiple"
                      : "none"
                  }
                  headerClassName={`headercolumnsPrime`}
                  className={"columnsPrime"}
                  headerStyle={{ width: "3em" }}
                ></Column>
                {createColumns(
                  projectRemovalApprovalData?.headers,
                  true,
                  false,
                  projectRemovalApprovalData?.data,
                  true,
                  false,
                  false,
                  {
                    crud_removeAccess: true,
                  },
                  {
                    crud_removeAccess: ProjectRemovalApproval,
                  },
                  {
                    crud_removeAccess_props: {
                      selectedUser: selectedUser,
                      setSelectedUser: setSelectedUser,
                      disableBulkButtons: disableBulkButtons,
                      setProjectAccessApprovalDialog:
                        setProjectAccessApprovalDialog,
                      bulkActionTrigger: bulkActionTrigger,
                    },
                  }
                )}
              </DataTable>
            )}
          </div>
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Project Access"
          visible={requestAccessDialog}
          onHide={() => {
            setRequestAccessDialog(false);
            setSelectedProjectForAccess([]);
            setSelectedAccess("");
          }}
          className="accessApprovalDialog"
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "60vw" }}
        >
          {requestAccessDialogContent()}
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Blocked Users"
          visible={blockedUsersDialog}
          onHide={() => {
            setBlockedUsersDialog(false);
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
        >
          {blockedUsers()}
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Delete Project"
          visible={deleteDialog}
          onHide={() => {
            setDeleteDialog(false);
            toDeletePId.current = null;
            toDeletePName.current = null;
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
        >
          {deleteLoading ? (
            <div className="py-5">
              {loadingData("Deleting " + toDeletePName.current)}
            </div>
          ) : (
            <>
              <div className="text-center mt-3 py-2 mx-3">
                <h5>
                  Are you sure you want to delete : {toDeletePName.current}
                </h5>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <div
                  onClick={() => {
                    deletePid(toDeletePId.current);
                  }}
                  className="btn btn-success"
                >
                  <FontAwesomeIcon
                    className="ms-1 tabIcon me-1"
                    icon={faCheck}
                  />
                  Yes
                </div>
                <div
                  onClick={() => {
                    setDeleteDialog(false);
                  }}
                  className="btn btn-danger mx-2"
                >
                  <FontAwesomeIcon
                    className="ms-1 tabIcon me-1"
                    icon={faXmark}
                  />
                  No
                </div>
              </div>
            </>
          )}
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Create Project"
          visible={createProjectDialog}
          onHide={() => {
            setCreateProjectDialog(false);
            setProjectName("");
            setEnv([]);
            setProjectDescription("");
            setSelectedDevs([]);
            setSelectAllDev(false);
            setSelectedPOC([]);
            setSelectAllPOC(false);
            setQaLead([]);
            setSelectAllLead(false);
            setQaTeam([]);
            setSelectAllTeam(false);
            setProjectNameAlreadyExists(false);
            setIsProjectNameValid(false);
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "66vw" }}
        >
          {createProjectDetails("create")}
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header="Edit Project Details"
          visible={editProjectDialog}
          onHide={() => {
            setEditProjectDialog(false);
            setProjectName("");
            setEnv([]);
            setProjectDescription("");
            setSelectedDevs([]);
            setSelectAllDev(false);
            setSelectedPOC([]);
            setSelectAllPOC(false);
            setQaLead([]);
            setSelectAllLead(false);
            setQaTeam([]);
            setSelectAllTeam(false);
            setProjectNameAlreadyExists(false);
            setIsProjectNameValid(false);
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "66vw" }}
        >
          {createProjectDetails("edit")}
        </Dialog>
        <Dialog
          blockScroll={true}
          draggable={false}
          header={`Project Users Access : ${editAccessDialogTitle.current}`}
          visible={editAccessDialog}
          onHide={() => {
            setEditAccessDialog(false);
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw" }}
        >
          <ul className="nav nav-pills mb-3" id={"pills-tab"} role="tablist">
            <li className="nav-item mr-1" role="presentation">
              <div
                className="nav-link changeRoleLink active"
                id={"pills-changeRole-tab-"}
                data-toggle="pill"
                data-target={"#pills-changeRole"}
                type="button"
                role="tab"
                aria-selected="true"
              >
                Change Roles
              </div>
            </li>
            <li className="nav-item" role="presentation">
              <div
                className="nav-link addUserLink"
                id={"pills-addUser-tab-"}
                data-toggle="pill"
                data-target={"#pills-addUser-"}
                type="button"
                role="tab"
                aria-selected="false"
              >
                Add Users
              </div>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id={"pills-changeRole"}
              role="tabpanel"
              aria-labelledby={"pills-changeRole-tab-"}
            >
              {projectAccessUsers &&
                Object.keys(projectAccessUsers).length > 0 ? (
                <>
                  <DataTable
                    reorderableColumns
                    onFilter={(e) => {
                      setUserFilters(e.filters);
                    }}
                    resizableColumns
                    stripedRows
                    columnResizeMode="expand"
                    value={
                      projectAccessUsers ? projectAccessUsers["data"] : null
                    }
                    paginator
                    rows={25}
                    dataKey="id"
                    filters={userFilters}
                    rowsPerPageOptions={[5, 10, 25, 50, 80, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    scrollHeight={"65vh"}
                    filterDisplay="menu"
                    responsiveLayout="scroll"
                    emptyMessage={<NoData />}
                    currentPageReportTemplate="Total {totalRecords} User(s) Found"
                    removableSort
                    className="test-stepTable m-2"
                  >
                    {createColumns(
                      projectAccessUsers?.headers,
                      true,
                      true,
                      projectAccessUsers?.data,
                      true,
                      null,
                      null,
                      {
                        crud_user: true,
                        user_access: true,
                      },
                      {
                        crud_user: EditUserAccess,
                        user_access: ChangeRole,
                      },
                      {
                        crud_user_props: {
                          edit_roles: { editProjectAccess },
                          project_name: editAccessDialogTitle.current,
                        },
                        user_access_props: {
                          edit_roles: { editProjectAccess },
                          project_name: editAccessDialogTitle.current,
                        },
                      }
                    )}
                  </DataTable>
                </>
              ) : (
                <DataTable
                  responsiveLayout="scroll"
                  header={
                    <>
                      <div className="headingFont">
                        <Skeleton
                          width="15rem"
                          className="my-3 ms-2"
                        ></Skeleton>
                      </div>
                    </>
                  }
                  value={skeletonRows}
                  className="p-datatable-striped mt-4 test-stepTable"
                >
                  <Column
                    header={
                      <Skeleton
                        width="5rem"
                        className="mb-2 mx-auto"
                      ></Skeleton>
                    }
                    style={{ width: "25%" }}
                    body={skeletonBodyTemplate}
                  ></Column>
                  <Column
                    header={
                      <Skeleton
                        width="5rem"
                        className="mb-2 mx-auto"
                      ></Skeleton>
                    }
                    style={{ width: "25%" }}
                    body={skeletonBodyTemplate}
                  ></Column>
                  <Column
                    header={
                      <Skeleton
                        width="5rem"
                        className="mb-2 mx-auto"
                      ></Skeleton>
                    }
                    style={{ width: "33%" }}
                    body={skeletonBodyTemplate}
                  ></Column>

                  <Column
                    header={
                      <Skeleton
                        width="5rem"
                        className="mb-2 mx-auto"
                      ></Skeleton>
                    }
                    style={{ width: "27%" }}
                    body={skeletonBodyTemplate}
                  ></Column>
                </DataTable>
              )}
            </div>
            <div
              className="tab-pane fade"
              id={"pills-addUser-"}
              role="tabpanel"
              aria-labelledby={"pills-addUser-tab-"}
            >
              {/* <div>Add User</div> */}
              <AddUser
                p_id={p_id}
                projectData={projectAccessUsers ? projectAccessUsers?.data : []}
                users={sameCompanyUsers}
              />
            </div>
          </div>
        </Dialog>
        <AdminAccessApproval
          accessApprovalDialog={accessApprovalDialog}
          setAccessApprovalDialog={setAccessApprovalDialog}
          selectedProjectForAccess={selectedProjectForAccess}
          setSelectedProjectForAccess={setSelectedProjectForAccess}
          accessApprovalData={accessApprovalData}
          setAccessApprovalData={setAccessApprovalData}
        />
      </div>
    </>
  );
};

export default Admin;
