import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Button } from "primereact/button";
import commentsService from "../../../../services/Comments/comments.service";
import { handleToastErrorCatch } from "../../../Helper/ToastHelper";
import Comment from "./Comment";
import { useSelector } from "react-redux";
import { connectSocket } from "../../../Helper/SocketHelper";

import { CommentInputSkeleton, NoCommentsSkeleton } from "./CommentSkeleton";

const Comments = ({ props }) => {
  const [commentBody, setCommentBody] = useState("");
  const [userFocus, setUserFocus] = useState("read");
  const [commentBtnLoading, setCommentBtnLoading] = useState(false);
  const [getCommentLoading, setGetCommentLoading] = useState(false);
  const [comments, setComments] = useState(null);
  const commentId = useRef(null);
  const userCompanyName = useSelector(
    (state) => state.auth.userProfile.company
  );
  const [socketMessage, setSocketMessage] = useState(null);
  const [socketId, setSocketId] = useState(null);



  const fullname = `${props.userDetail.firstName} ${props.userDetail.lastName}`;

  const notifyClient = (e) => {
    if (e?.body) {
      let wsBody = JSON.parse(e?.body);
      setSocketMessage(wsBody?.message?.toLowerCase());
      setSocketId(wsBody?.commentParentId);
      if (
        (wsBody?.message?.toLowerCase() === "comment updated" ||
          wsBody?.message?.toLowerCase() === "reaction updated") &&
        wsBody?.commentParentId === "null"
      ) {
        getParentComments();
      }
    }
  };

  useEffect(() => {
    getParentComments();

    connectSocket("report", "comments/private", commentId, notifyClient);

    return () => {
      commentId?.current?.unsubscribe();
    };


  }, []);

  const getParentComments = () => {
    setGetCommentLoading(true);
    let payload = {
      s_run_id: props.s_run_id,
      p_id: props.p_id,
    };

    commentsService
      .getAllComments(payload)
      .then((data) => {
        setComments(data);
        setGetCommentLoading(false);
      })
      .catch((err) => {
        if (userCompanyName?.toLowerCase() !== "individual")
          handleToastErrorCatch(err, () => setGetCommentLoading(false))
      }
      );
  };

  const postComment = () => {
    setCommentBtnLoading(true);

    let payload = {
      commentBody: commentBody,
      srunId: props.s_run_id,
      pid: props.p_id,
      createdByName: fullname,
    };

    if (props.userDetail.imgUrl) {
      payload.profileUrl = props.userDetail.imgUrl;
    }

    commentsService
      .createComment(payload)
      .then((data) => {
        setCommentBody("");
        setCommentBtnLoading(false);
        setUserFocus("read");
      })
      .catch((err) =>
        handleToastErrorCatch(err, () => setCommentBtnLoading(false))
      );
  };

  return (
    <div className="container-fluid">
      <div className="px-5 pt-3 commentSection inputBorder rounded">
        <h1 className="my-4 commentHeading">Comments </h1>

        <div className="w-75 sticky-top">
          <InputTextarea
            className="w-100 commentInput inputBorder"
            value={commentBody}
            placeholder="Add your comment here ..."
            onFocus={() => setUserFocus("comment")}
            onChange={(e) => setCommentBody(e.target.value)}
            rows={userFocus === "read" ? 1 : 5}
            autoResize={userFocus === "write"}
          />
          {userFocus === "comment" && (
            <div className="commentActionBtn d-flex justify-content-end">
              <Button
                label="Cancel"
                disabled={commentBtnLoading}
                type="button"
                className="btn-success btn btn-sm commentBtn mx-2 mt-1 me-2 p-ThemeBtn"
                onClick={() => {
                  setCommentBody("");
                  setUserFocus("read");
                }}
              />
              <Button
                label="Post"
                disabled={!commentBody}
                type="button"
                icon={commentBtnLoading ? "pi pi-spin pi-spinner me-2" : ""}
                loading={commentBtnLoading}
                iconPos="left"
                className="btn-success btn btn-sm commentBtn mx-2 mt-1 me-2 p-ThemeBtn"
                onClick={() => {
                  postComment();
                }}
              />
            </div>
          )}
        </div>
        {comments?.length > 0 ? (
          <div className="w-75 mt-4 my-2 commentBody pt-4 px-4">
            {comments?.map((cmnt) => {
              return (
                <>
                  <Comment
                    socketId={socketId}
                    socketMessage={socketMessage}
                    limit={0}
                    fullname={fullname}
                    id={cmnt.commentId}
                    props={props}
                    comment={cmnt}
                    setSocketId={setSocketId}
                    setSocketMessage={setSocketMessage}
                  />
                  <hr />
                </>
              );
            })}
          </div>
        ) : getCommentLoading ? (
          <CommentInputSkeleton />
        ) : (
          <NoCommentsSkeleton />
        )}
      </div>
    </div>
  );
};

export default Comments;
